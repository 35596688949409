import React, { useState } from 'react'
import { Tab, Tabs, Icon } from '@mui/material'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const Wrapper = styled.div`
  .tabs {
    & > div {
      background-color: transparent !important;
    }
  }

  .custom-icon {
    color: ${themeColors.whiteColor} !important;
    font-size: 2.2rem !important;
    width: 50px;
    &.active {
      color: ${themeColors.vomitColor} !important;
    }
  }
`

export default function FileGeneratorTabs({ tabs = [], onTabChange }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const handleChange = (_, index) => {
    setActiveTabIndex(index)
    onTabChange(index)
  }

  return (
    <Wrapper>
      <Tabs variant="fullWidth" onChange={handleChange} value={activeTabIndex} className="tabs" textColor="inherit">
        {tabs.map(({ icon }, tabIndex) => (
          <Tab
            key={tabIndex}
            data-testid={`file-generator-tab-${tabIndex}`}
            value={tabIndex}
            icon={<Icon className={`${icon} custom-icon ${activeTabIndex === tabIndex ? 'active' : ''}`} />}
          />
        ))}
      </Tabs>
    </Wrapper>
  )
}
