import React, { useRef, useEffect } from 'react'
import { select, scaleLinear, extent, max, min, line, axisBottom, format, curveBundle } from 'd3'
import { threshold, filterOverlappingTicks, getClassRange } from './helpers'

/**
 * @typedef {Object} classes
 * @property {string} color - The color associated with the class.
 * @property {string} class - The range or category of the class.
 * @property {number} value - The numerical value representing the class.
 * @property {number} fraction - The fraction associated with the class.
 * @property {number} surface - The surface area or value associated with the class.
 * @property {oblect} interval - The interval value
 */

/**
 * AreaChart component renders an area chart based on provided data and classes.
 *
 * @property {number[]} intervals - An array of interval values.
 * @property {number[]} counts - An array of count values corresponding to the intervals.
 * @param {Class[]} props.classes - The classes defining ranges and colors for the chart.
 * @returns {JSX.Element} The rendered SVG element of the area chart.
 */

const AreaChart = ({ counts, intervals, classes }) => {
  const svgRef = useRef()

  useEffect(() => {
    const svgElement = svgRef.current
    const margin = { top: 10, right: 10, bottom: 35, left: 10 }
    const width = 300 - margin.left - margin.right
    const height = 130 - margin.top - margin.bottom

    const g = select(svgElement)
    g.selectAll('*').remove()

    const svg = g
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg.append('rect').attr('width', width).attr('height', height).attr('fill', 'rgba(255, 255, 255, 0.1)').attr('x', 0).attr('x', 0).attr('y', 0)

    const xScale = scaleLinear().domain(extent(intervals)).range([0, width])
    const yScale = scaleLinear()
      .range([height, 0])
      .domain([0, max(counts)])

    const areaPath = d => {
      const topCurve = line()
        .curve(curveBundle.beta(0.5))
        .x(d => xScale(d.x))
        .y(d => yScale(d.y))(d)

      const bottomCurve = line()
        .x(d => xScale(d.x))
        .y(() => height)(d.reverse())

      return topCurve + 'L' + bottomCurve.slice(1) + 'Z'
    }

    const minInterval = min(intervals)
    const maxInterval = max(intervals)

    let tickPositions = new Set()

    const areaGroup = svg.append('g').attr('class', 'area-group')
    classes.forEach(cls => {
      if (cls.surface !== 0 && cls.interval) {
        const classRange = getClassRange(cls.interval, minInterval, maxInterval)
        const areaData = counts.map((value, i) => {
          return {
            x: intervals[i],
            y: value,
            inClass: classRange.length === 2 ? intervals[i] > classRange[0] && intervals[i] < classRange[1] : intervals[i] >= classRange[0]
          }
        })

        const filteredData = areaData.filter(d => d.inClass)

        if (filteredData.length > 0) {
          areaGroup.append('path').datum(filteredData).attr('d', areaPath).attr('fill', cls.color).attr('class', 'colored-classes')
        }

        if (classRange[0] !== null) {
          tickPositions.add(classRange[0])
        }
        tickPositions.add(max(intervals))
      }
    })

    tickPositions = [...new Set(Array.from(tickPositions))].sort((a, b) => a - b)
    tickPositions = filterOverlappingTicks(tickPositions, threshold)

    const xAxis = svg
      .append('g')
      .attr('transform', `translate(0,${height})`)
      .attr('color', '#26637f')
      .call(axisBottom(xScale).tickValues(tickPositions).tickFormat(format('.2f')))

    xAxis.selectAll('text').attr('fill', 'white').attr('font-size', '9px')
  }, [classes, counts, intervals])

  return <svg ref={svgRef}></svg>
}

export default AreaChart
