import React from 'react'
import styled from 'styled-components'
import LeftBarTop from './LeftBarTop'
import LeftBarBottom from './LeftBarBottom'
import { UIStyles } from '../../theme'
import { withCurrentRoute } from '../HOCComponents/withCurrentRoute'

export const LeftBarWrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 60px;
  background-color: ${UIStyles.blueColorOpacity};
  width: 60px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${UIStyles.lightGrey};
  z-index: 10;
`

const ComponentsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

function LeftBar(props) {
  if (props.currentLocation.isSampleImage || props.currentLocation.isBiLabs || props.currentLocation.isAccounts) return null

  return (
    <LeftBarWrapper>
      <ComponentsWrapper>
        <LeftBarTop />
        <LeftBarBottom />
      </ComponentsWrapper>
    </LeftBarWrapper>
  )
}

export default withCurrentRoute(LeftBar)
