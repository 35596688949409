// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Очень низкий",
  "% Mapa alertas": "% Карта предупреждений",
  "% Media Fosforo": "% Среднее содержание фосфора",
  "% Media Nitrogeno": "% Среднее содержание азота",
  "% Media Potasio": "% Среднее содержание калия",
  "% resiembra": "% пересев",
  "0.10 Low": "0.10 Низкий",
  "0.20 Very Low": "0.20 Очень низкий",
  "0.25 Very Low": "0.25 Очень низкий",
  "0.30 Low": "0.30 Низкий",
  "0.30 Medium": "0.30 Средний",
  "0.40  Low": "0.40  Низкий",
  "0.40 Medium": "0.40 Средний",
  "0.45 High": "0.45 Высокий",
  "0.50 High": "0.50 Высокий",
  "0.55 Medium": "0.55 Средний",
  "0.65 Very High": "0.65 Очень высокий",
  "0.70 High": "0.70 Высокий",
  "0.85 Very High": "0.85 Очень высокий",
  "abonado de cobertera": "Покровное удобрение",
  "abonado de fondo": "Основное удобрение",
  "abonado de fondo ": "Основное удобрение",
  "abonado dosificado": "Дозированное удобрение",
  "abonado tasa fija": "Удобрение с фиксированной нормой",
  "abonado zonificado": "Зонированное удобрение",
  "accumulated vegetative growth": "Накопленный вегетативный рост",
  "Acre": "Акр",
  "active": "активный",
  "Add at least one recipient (+)": "Добавьте хотя бы одного получателя (+)",
  "Add Field Button": "Кнопка добавления поля",
  "Add Field Tutorial": "Учебник: Добавление полей",
  "add location": "добавить местоположение",
  "Add new sample": "Добавить новый образец",
  "add photo": "добавить фото",
  "added to pending": "добавлено в ожидание",
  "Advanced": "Продвинутый",
  "advanced season parameters": "продвинутые параметры сезона",
  "afección": "Поражение",
  "afección extensivo": "Экстенсивное поражение",
  "Affected Surface": "Пораженная поверхность",
  "After filling the form, you can draw the field": "После заполнения формы вы можете нарисовать поле",
  "agrodelivery": "АгроДоставка",
  "all": "все",
  "ALMENDRA": "МИНДАЛЬ",
  "alto estrés hídrico": "Высокий водный стресс",
  "alto pot prod": "Высокая производительность калия",
  "ambiente": "Среда",
  "An error occured": "Произошла ошибка",
  "An error occurred from the server. Please try again": "Произошла ошибка на сервере. Пожалуйста, попробуйте еще раз",
  "And that's it!": "Вот и все!",
  "anexo información": "Приложенная информация",
  "Applied filters": "Примененные фильтры",
  "Apply": "Применить",
  "apply date filter": "Применить фильтр по дате",
  "Apply selection": "Применить выбор",
  "Are you sure to close the editor?": "Вы уверены, что хотите закрыть редактор?",
  "Are You sure?": "Вы уверены?",
  "area": "площадь",
  "Area": "Площадь",
  "ATR": "Общее извлекаемое количество сахара",
  "author": "Автор",
  "Automatic harvest": "Автоматический сбор урожая",
  "auxiliary files": "Вспомогательные файлы",
  "back": "назад",
  "bajo estrés hídrico": "Низкий водный стресс",
  "bajo pot prod": "Низкая производительность калия",
  "Base temperature": "Базовая температура",
  "baseLayer": "Базовый слой",
  "Basic": "Основной",
  "basic season parameters": "параметры основного сезона",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"глазное пятно\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"глазное пятно\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"глазное пятно\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"глазное пятно\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"глазное пятно\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Обе начальная и конечная точки линии разреза должны быть за пределами полигона/круга",
  "Buried": "Погребенный",
  "calendar": "Календарь",
  "calibre": "Калибр",
  "camera error message": "Похоже, возникла проблема с разрешениями для камеры",
  "camera_unavailable": "камера недоступна",
  "Can't modify field with active planet subscription": "Невозможно изменить поле с активной подпиской на планету",
  "cancel": "Отменить",
  "cane_num": "Количество тростника",
  "cantidad": "Количество",
  "capa base": "Базовый слой",
  "Capture screenshot": "Сделать скриншот",
  "Change opacity": "Изменить непрозрачность",
  "Choose your layer": "Выберите свой слой",
  "Choose your tool": "Выберите свой инструмент",
  "Circle Drawing": "Рисование круга",
  "class": "Класс",
  "classes": "Классы",
  "classes number": " Количество классов",
  "clear": "Очистить",
  "Clear all": "Очистить все",
  "clear form": "Очистить форму",
  "clear selected fields": "Очистить выбранные поля",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Нажав эту кнопку, вы можете переключить видимость выбранного слоя.",
  "clientDeviation": "Отклонение клиента",
  "Close": "Закрыть",
  "Close editor": "Закрыть редактор",
  "Close selection": "Закрыть выбор",
  "Close the temporal Comparison": "Закрыть временное сравнение",
  "close_selected": "Закрыть выбранное",
  "closed": "закрыто",
  "cloud": "Облако",
  "Cloud Coverage": "Облачное покрытие",
  "comment": "комментарий",
  "comp. temporal target": "Временная цель сравнения",
  "comp. temporal variabilidad": "Временная изменчивость сравнения",
  "comparación de conteo": "Сравнение количества (%)",
  "comparación de estado hídrico": "Сравнение водного состояния (%)",
  "comparación de estado hídrico drone": "Сравнение водного состояния - дрон (%)",
  "comparación de estado hídrico sat": "Сравнение водного состояния - SAT (%)",
  "comparación de estimación de producción": "Сравнение оценки производства (%)",
  "comparación de fósforo": "Сравнение фосфора (%)",
  "comparación de mapa de alertas": "Сравнение карты предупреждений (%)",
  "comparación de mapa de faltas": "Сравнение карты недостатков (%)",
  "comparación de media de conteo": "Сравнение среднего количества (%)",
  "comparación de media de estado hídrico": "Сравнение среднего водного состояния (%)",
  "comparación de media de fósforo": "Сравнение среднего фосфора (%)",
  "comparación de media de mapa de alertas": "Сравнение средней карты предупреждений (%)",
  "comparación de media de mapa de faltas": "Сравнение средней карты недостатков (%)",
  "comparación de media de nitrógeno": "Сравнение среднего азота (%)",
  "comparación de media de potasio": "Сравнение среднего калия (%)",
  "comparación de media de producción total": "Сравнение среднего общего производства (%)",
  "comparación de media de rendimiento graso": "Сравнение среднего жирного выхода (%)",
  "comparación de medias de estimación de producción": "Сравнение средних оценок производства (%)",
  "comparación de medias de ndvi": "Сравнение средних значений NDVI (%)",
  "comparación de medias de proteína": "Сравнение среднего содержания белка (%)",
  "comparación de medias de variabilidad": "Сравнение среднего уровня изменчивости (%)",
  "comparación de ndvi": "Сравнение NDVI (%)",
  "comparación de nitrógeno": "Сравнение азота (%)",
  "comparación de nitrógeno relativo": "Сравнение относительного содержания азота (%)",
  "comparación de potasio": "Сравнение калия (%)",
  "comparación de producción total": "Сравнение общего производства (%)",
  "comparación de proteína": "Сравнение белка (%)",
  "comparación de rendimiento graso": "Сравнение жирного выхода (%)",
  "comparación de sacarosa": "Сравнение сахарозы",
  "comparación de variabilidad": "Сравнение изменчивости (%)",
  "confirm": "Подтвердить",
  "Congratulations! Your field has been created.": "Поздравляем! Ваше поле создано.",
  "Congratulations! Your field has been edited.": "Поздравляем! Ваше поле отредактировано.",
  "Contact": "Контакт",
  "Contenido de sacarosa ( % )": "Уровень сахарозы ( % )",
  "conteo - pdf rgb": "подсчет - pdf rgb",
  "conteo - shps": "подсчет - shps",
  "conteo de individuos": "Индивидуальный подсчет",
  "conteo lechugas": "Подсчет Салата",
  "continue": "продолжить",
  "Cookies and Privacy": "Файлы cookie и конфиденциальность",
  "CORN": "КУКУРУЗА",
  "correction": "Коррекция",
  "Corte nitrogeno": "Срез азота",
  "cost": "Стоимость",
  "count (units)": "Подсчет (единицы)",
  "Counting": "Подсчет",
  "create": "создать",
  "Create New Field": "Создать новое поле",
  "CREATE_RANGES": "На основе гистограммы изображения создайте диапазоны с процентом аналогичных данных в каждом диапазоне.",
  "created": "Создано",
  "createFieldForm_create_button": "Создать поле",
  "createFieldForm_draft_button": "Сохранить черновик",
  "createFieldForm_info_message": "Сохранение в виде черновика позволяет временно хранить данные на этом устройстве, чтобы вы могли редактировать или добавлять геометрию перед сохранением в Layers.",
  "crecimiento cultivo": "Рост урожая",
  "Crop": "Урожай",
  "Crop type": "Тип урожая",
  "CROP_INSPECTION": "Инспекция урожая",
  "cultive": "Урожай",
  "Cultivo": "Урожай",
  "current season": "текущий сезон",
  "current selection could take a moment to process": "Текущий диапазон дат был увеличен, запрос может занять некоторое время для обработки",
  "Customer": "Клиент",
  "customerProductionEstimation": "Оценка производства клиента",
  "customers": "Клиент",
  "cut": "Срез",
  "Cutter": "Резак",
  "dashboard": "Панель управления",
  "date": "Дата",
  "DD/MM/YYYY": "ДД/ММ/ГГГГ",
  "delete": "удалить",
  "Delete": "Удалить",
  "Delete downloaded report": "Удалить загруженный отчет",
  "delete downloaded sample": "удалить загруженный образец",
  "Delete field": "Удалить поле",
  "delete layers?": "удалить слои?",
  "delete sample": "удалить образец",
  "delete sample edition": "удалить редакцию образца",
  "delete_geometries_action_button": "Удалить",
  "delete_geometries_alert_message": "Вы уверены, что хотите удалить геометрию?",
  "delete_geometries_alert_title": "Удалить геометрию",
  "DeleteField": "Поле было удалено",
  "Deliverables tutorial": "Учебник: передачи",
  "Description": "Описание",
  "Deselect": "Отменить выбор",
  "deselect all": "Отменить выбор всех",
  "deviation": "Отклонение",
  "Did you forget your password ?": "Забыли пароль?",
  "discard_changes_alert_message": "У вас есть несохраненные изменения. Вы уверены, что хотите их отменить и покинуть этот экран?",
  "discard_changes_alert_title": "Отменить изменения?",
  "discard_changes_cancel_button": "Отмена",
  "discard_changes_discard_button": "Отменить",
  "disponible en visor": "Доступно в просмотрщике",
  "do you want to delete": "вы хотите удалить",
  "documentation": "Документация",
  "don't show again": "больше не показывать",
  "Done": "Готово",
  "dosificación genérica": "Общая дозировка",
  "Dosis Media Cliente": "Средняя доза клиента",
  "Dosis media Propuesta": "Предложенная средняя доза",
  "dosis riego": "Доза орошения",
  "Download": "Скачать",
  "download failed": "Не удалось завершить следующие загрузки",
  "Download list": "Список загрузок",
  "Download selected fields samples": "Скачать образцы выбранных полей",
  "download selected geometries": "Скачать выбранные геометрии",
  "download values": "Скачать значения",
  "downloaded": "загружено",
  "downloading layers": "загрузка слоев",
  "downloads": "Загрузки",
  "Drag and drop your layers to arrange them in the list.": "Перетащите свои слои, чтобы организовать их в списке",
  "Drag Fields": "Перетащить поля",
  "Drag your layers": "Перетащить свои слои",
  "Draw Field": "Нарисовать поле",
  "dron": "Дрон",
  "Dron sectores": "Секторы дрона",
  "drop here": "бросьте сюда",
  "Dry": "Сухой",
  "dry matter": "Сухое вещество",
  "dryMatterField": "Сухое вещество",
  "dryMatterLaboratory": "Лаборатория сухого вещества",
  "dsm": "DSM",
  "earliest harvest date": "Самая ранняя дата сбора урожая",
  "edit": "редактировать",
  "Edit Field": "Редактировать поле",
  "Edit geometry": "Редактировать геометрию",
  "edit range": "Редактировать диапазон",
  "Edit values": "Редактировать значения",
  "ej 100": "Пример 100",
  "End Date": "Дата окончания",
  "Equal Interval": "Равный интервал",
  "error fetching classes": "Небо слишком облачное в выбранную дату. Отрегулируйте облачный фильтр в календаре, чтобы найти более ясный день.",
  "estado hídrico": "Водное состояние",
  "estado hídrico (ndmi)": "Водное состояние (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Оценка",
  "estimación producción": "Оценка производства",
  "Estimacion produccion ( Tn / Ha )": "Оценка производства ( Tn / Ha )",
  "estimación producción cualitativa": "Качественная оценка производства",
  "estimación proteína": "Оценка белка",
  "Estimacion proteina ( % )": "Оценка белка ( % )",
  "estimación sacarosa": "Оценка сахарозы",
  "estimación sacarosa ": "Оценка сахарозы",
  "estimación sacarosa cualitativa": "Качественная оценка сахарозы",
  "Estimacion Total ( Kg )": "Общая оценка ( кг )",
  "estimation gauging": "Оценка измерений",
  "estrategia de dosificacion": "Стратегия дозирования",
  "estrés hídrico (lws)": "Водный стресс (LWS)",
  "evolución de NDVI": "Эволюция NDVI",
  "evolution": "эволюция",
  "Ex : comment": "Пример: комментарий",
  "Ex : field R02": "Пример: поле R02",
  "Ex : Tresbolillos, row, tree": "Пример: Tresbolillos, ряд, дерево",
  "expected_final_weight": "Ожидаемый конечный вес",
  "external_id": "Внешний ID",
  "extra parameters": "дополнительные параметры",
  "falhas carreadores": "Разрывы носителей",
  "falhas internas": "Внутренние разрывы",
  "falhas terraços": "Разрывы на террасах",
  "falla": "Разрыв",
  "fat performance": "Производительность жира",
  "faults map": "Карта неисправностей",
  "fdn": "ФДН",
  "Fecha de vuelo": "Дата полета",
  "Fecha Plantacion": "Дата посадки",
  "Feddan": "Феддан",
  "fertilization strategy": "Стратегия удобрения",
  "Fertilizer recommendation": "Рекомендация по удобрениям",
  "field": "поле",
  "Field Name": "Название поля",
  "Field without geometry": "Поле без геометрии",
  "FIELD_FILTER": "Фильтры поля",
  "field_name": "Название поля",
  "FIELD_TEMPORAL_EVOLUTION": "Временная эволюция",
  "fields": "Поля",
  "Fields without geometries": "Поля без геометрий",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Файл не может быть сгенерирован из-за большого количества облаков. Пожалуйста, выберите другую дату",
  "File generator": "Генератор файлов",
  "File Generator": "Генератор файлов",
  "File name": "Имя файла",
  "file type": "Тип файла",
  "file-is-not-available-in-selected-language": "Этот файл недоступен на выбранном языке. Сгенерирован на английском.",
  "Fill plantation date of current field, showing one year of historic data": "Заполните дату посадки текущего поля, показывая один год исторических данных",
  "Fill the field form": "Заполните форму поля",
  "Fill the form to apply for all our services": "Заполните форму, чтобы подать заявку на все наши услуги",
  "filtered field": "отфильтрованное поле",
  "filtered fields": "отфильтрованные поля",
  "Filters": "Фильтры",
  "final_weight": "Конечный вес",
  "fitopatógenos": "Фитопатогены",
  "Flight Slider": "Слайдер полета",
  "FLIGHT_FILTER": "Временное сравнение",
  "flights type": "Типы полетов",
  "fmsavi": "FMSAVI",
  "for everyone": "для всех",
  "for me": "для меня",
  "forecasts": "Прогнозы",
  "formulario de abonado": "Форма удобрения",
  "formulario de dosis": "Форма дозировки",
  "fósforo foliar": "Фосфор в листьях",
  "fr": "Франций",
  "Fraction": "Фракция",
  "free": "бесплатно",
  "from": "От",
  "fruits_100_weight": "Вес 100 фруктов",
  "fruits_actual1_weight": "Фактический вес фруктов",
  "Fuente de informacion": "Источник",
  "gallery": "галерея",
  "gauging date": "Дата измерения",
  "generation error": "Ошибка генерации",
  "geometry": "геометрия",
  "get a free trial": "бесплатная пробная версия",
  "Get back to editor": "Вернуться к редактору",
  "global files": "Скачать предварительную обработку, обучение и прогнозы PDF и CSV файлы",
  "global layer": "Глобальный слой",
  "gm_cut_number": "Номер среза",
  "gm_data_generation": "Дата спутниковых данных (недели)",
  "gm_day_cycle": "Дни выращивания",
  "gm_harvest_estimated_date": "Оценочная дата урожая (недели)",
  "gm_hemav_atr_estimation": "Прогноз общего извлекаемого сахара",
  "gm_hemav_pol_estimation": "Прогноз поляризации",
  "gm_hemav_prodph_estimation": "Прогноз урожайности",
  "gm_hemav_realprodph_lastseason": "Фактическая урожайность за предыдущий сезон",
  "gm_hemav_sac_estimation": "Прогноз сахарозы",
  "gm_luvi_date_generation": "Дата спутниковых данных - LUVI (недели)",
  "gm_luvi_day_cycle": "Дни выращивания (LUVI)",
  "gm_luvi_mean": "Вегетативная сила (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Изменчивость (NDVI-LUVI)",
  "gm_lws_mean": "Водный стресс (LWS)",
  "gm_ndmi_date_generation": "Дата спутниковых данных - NDMI (недели)",
  "gm_ndmi_day_cycle": "Дни выращивания (NDMI)",
  "gm_ndre_date_generation": "Дата спутниковых данных - NDRE (недели)",
  "gm_ndre_day_cycle": "Дни выращивания (NDRE)",
  "gm_sar_biomass_cat": "Категориальная биомасса (SAR)",
  "gm_season_label": "Сезон",
  "gm_sub_type_name": "Сорт",
  "gm_type_name": "Культура",
  "go to field": "Перейти к полю",
  "grid": "сетка",
  "harvest date": "Дата сбора урожая",
  "harvest planning": "Планирование сбора урожая",
  "HARVEST_PLANNING_TOOL": "Инструмент планирования сбора урожая",
  "HARVEST_PREDICTION": "Прогноз урожая",
  "Hectare": "Гектар",
  "HEMAV Default": "По умолчанию HEMAV",
  "Here, you can play with the preview of your different layers.": "Здесь вы можете поиграть с предварительным просмотром ваших различных слоев",
  "hide": "скрыть",
  "Hide All Layers": "Скрыть все слои",
  "hide filter list": "скрыть список фильтров",
  "Hide Global Layer": "Скрыть глобальный слой",
  "Hide Measure Tools": "Скрыть инструменты измерения",
  "high": "Высокий",
  "High contrast map": "Карта с высоким контрастом",
  "high level": "Высокий",
  "high need": "Высокая потребность",
  "high water stress": "Высокий водный стресс",
  "higher clay content": "Большее содержание глины",
  "humedad de suelo - beta (%)": "Влажность почвы - Бета (%)",
  "humedad vegetativa (ndmi)": "Вегетативная влажность (NDMI)",
  "Hybrid map": "Гибридная карта",
  "Hybrid View": "Гибридный вид",
  "hydric status": "Гидрический статус - NDMI",
  "hydric status drone": "Гидрический статус - Дрон",
  "If foliar sampler haven't been made, the date you must enter is the current": "Если фолиарный отбор не был сделан, дата, которую вы должны ввести, является текущей",
  "If you have two or more flights, you can compare them temporally using this tool.": "Если у вас есть два или более полета, вы можете временно сравнить их, используя этот инструмент.",
  "If you have undone an action, you can press this button to redo it.": "Если вы отменили действие, вы можете нажать эту кнопку, чтобы повторить его.",
  "image": "изображение",
  "Impacto estimado": "Оценка воздействия",
  "In this tab, you can download the generated PDFs.": "В этой вкладке вы можете скачать сгенерированные PDF.",
  "individuals_num": "Число индивидуумов",
  "infection": "Инфекция",
  "info": "Информация",
  "Information": "Информация",
  "informe": "Отчет",
  "integración con maquinaria": "Интеграция с техникой",
  "Invalid credentials": "Неверные учетные данные",
  "invalid date": "Неверная дата",
  "Invalid email": "Неверный email",
  "invalid_geometry_error": "Неверная геометрия",
  "invest": "Инвестировать",
  "Irrigation recommendation": "Рекомендация по орошению",
  "Irrigation type": "Тип орошения",
  "item_num": "Номер позиции",
  "Keep selection": "Сохранить выбор",
  "label": "01.Ярлык",
  "Label": "Ярлык",
  "languaje": "Язык",
  "last sample": "Последний образец",
  "lat": "Широта",
  "layers": "Слои",
  "layers estrés hídrico (lws)": "Слои водного стресса (LWS)",
  "layers status": "Статус Layers",
  "Legal limitation": "Юридическое ограничение",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"кольцевая пятнистость\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"кольцевая пятнистость\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"кольцевая пятнистость\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"кольцевая пятнистость\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"кольцевая пятнистость\" - g5",
  "letter only": "Только буква",
  "linear meters": "Линейные метры",
  "líneas de cultivo": "Линии посева",
  "list": "список",
  "loading": "Загрузка",
  "location": "Местоположение",
  "Log in": "Войти",
  "logout": "Выйти",
  "lon": "Долгота",
  "looks like its taking too long": "похоже, это занимает слишком много времени",
  "low": "Низкий",
  "low level": "Низкий уровень",
  "low need": "Низкая потребность",
  "low water stress": "Низкий водный стресс",
  "lower clay content": "Меньшее содержание глины",
  "LUVI variability": "Изменчивость LUVI",
  "Machines Type": "Тип машин",
  "main_macronutrient": "Основной макронутриент",
  "malas hierbas": "Сорняки",
  "malas hierbas - hoja estrecha": "Сорняки - узколистные",
  "malas hierbas - hoja larga": "Сорняки - широколистные",
  "malas hierbas - zonificado": "Сорняки - зонированные",
  "malas hierbas shps": "Сорняки SHPs",
  "malas hierbas shps - hoja estrecha": "Сорняки SHPs - узколистные",
  "malas hierbas shps - hoja larga": "Сорняки SHPs - широколистные",
  "malas hierbas shps - zonificado": "Сорняки SHPs - зонированные",
  "maleza": "Сорняки",
  "Manage report": "Управление отчетом",
  "mapa de faltas": "Пропуски",
  "max 2 years": "максимум 2 года",
  "max comparable fields": "Максимум сопоставимых полей",
  "maximo de variacion": "Максимальное отклонение",
  "máximo vigor dos meses (ndvi)": "Максимальная сила два месяца (NDVI)",
  "máximo vigor un año (ndvi)": "Максимальная сила один год (NDVI)",
  "Maximun cloud coverage": "Максимальное облачное покрытие",
  "mean": "Среднее",
  "Measures tool tutorial": "Учебник: инструменты измерения",
  "Measures tools": "Инструменты измерения",
  "media de faltas": "Средние пропуски посева",
  "medias": "Средние",
  "medio pot prod": "Средняя производительность калия",
  "medium": "Средний",
  "medium clay content": "Среднее содержание глины",
  "medium level": "Средний уровень",
  "medium_weight_bigSize": "Средний вес больших фруктов",
  "medium_weight_mediumSize": "Средний вес средних фруктов",
  "medium_weight_smallSize": "Средний вес малых фруктов",
  "Mensual limit": "Месячный лимит",
  "Message": "Сообщение",
  "METEOROLOGICAL_STATISTICS": "Метеорологическая статистика",
  "mode": "Режим",
  "model type": "Тип модели",
  "Move the slider": "Переместите ползунок",
  "msavi": "MSAVI",
  "N_BUNCHES": "Количество гроздей",
  "Name": "Имя",
  "nameless field": "Поле без имени",
  "Natural Breaks (Jenks)": "Естественные разрывы (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Реальный",
  "ndvi-curvas de nivel": "NDVI-Уровневые линии",
  "Network request failed": "Ошибка соединения",
  "new sample": "новый образец",
  "New sample created.": "Новый образец создан.",
  "NITRO": "Азот",
  "nitrogen chlorophyll (ndre)": "Азот-хлорофилл (NDRE)",
  "nitrogen_assimilable": "Ассимилируемый азот",
  "nitrógeno foliar": "Фолярный азот",
  "Nivel de estres": "Уровень гидростресса",
  "no data": "Нет данных",
  "no data available": "Данные пока недоступны",
  "no data with current selection": "Нет данных с текущим выбором",
  "No fields selected": "Поля не выбраны",
  "no fields to clear": "Нет полей для очистки",
  "no_date": "Нет даты",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "На этот день нет изображений.",
  "Not a user yet ?": "Еще не пользователь?",
  "not enough data to display this chart": "Недостаточно данных для отображения этого графика",
  "Not supported by browser": "Не поддерживается вашим браузером",
  "notes": "заметки",
  "number only": "Только число",
  "Numero vuelo": "Количество полетов",
  "observations": "замечания",
  "offline access": "доступ оффлайн",
  "offline mode": "Оффлайн режим",
  "oil_yield": "Выход масла",
  "ok": "Ок",
  "Once you are done, click this button to save the changes.": "Когда закончите, нажмите эту кнопку, чтобы сохранить изменения.",
  "only-classes-3-and-mode-fixed-is-valid": "Только режим 'Hemav Default' и '3' класса являются допустимыми параметрами",
  "open": "открыть",
  "Open": "Открыть",
  "Open selection": "Открыть выбор",
  "open temporal comparison": "Открыть временное сравнение",
  "open_selected": "Открыть выбранное",
  "optimize": "Оптимизировать",
  "or": "или",
  "Order by a - z": "Сортировать по a - z",
  "Order by distance to the field": "Сортировать по расстоянию до поля",
  "Order by z - a": "Сортировать по z - a",
  "ourDeviation": "Отклонение Hemav",
  "ourProductionEstimation": "Оценка производства Hemav",
  "Parcela": "Поле",
  "Password": "Пароль",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD дерево",
  "pcd interpolado": "Интерполированный PCD",
  "pcd row": "PCD ряд",
  "pdf": "PDF",
  "pending": "в ожидании",
  "pending_field_map_info_confirm_delete_field_text": "Вы уверены, что хотите удалить это поле?",
  "pendingFields_missingGeo_message": "Некоторые поля не удалось создать Пожалуйста, добавьте к ним геометрию.",
  "pendingFields_serverError_message": "Произошла ошибка сервера. Пожалуйста, попробуйте ещё раз",
  "perimeter": "периметр",
  "phospohorus": "Фосфор",
  "phosporus": "Фосфор (ppm)",
  "photo": "фото",
  "photoid": "Фото ID",
  "photoId": "Фото",
  "picture_path": "Изображение",
  "plague": "Чума",
  "planned dosage": "Плановая дозировка",
  "Plant emitters": "Растительные эмиттеры",
  "plantation date": "Дата посадки",
  "Plantation date": "Дата посадки",
  "plantation_end_date_range": "Диапазон дат посадки",
  "plantation_start_date_range": "Диапазон дат посадки",
  "Plantations": "Плантации",
  "plants": "Растения",
  "Plants distance": "Расстояние между растениями",
  "plants_density_range": "Диапазон плотности растений",
  "Please wait or order one to have data to visualized.": "Запланируйте полет для получения данных по этому полю",
  "Please, choose a field variety": "Пожалуйста, выберите сорт поля",
  "Please, choose the field owner": "Пожалуйста, выберите владельца поля",
  "plural selected": "выбрано",
  "POL": "Поляризация",
  "Polygon drawing": "Рисование полигона",
  "posible falla": "Потенциальный разрыв",
  "posible maleza": "Потенциальные сорняки",
  "positive number only": "Только положительное число",
  "positive number only and minor 100": "Только положительное число и меньше 100",
  "pot. horticolar 90": "пот. садоводческий 90",
  "pot. otros cultivos 150": "пот. другие культуры 150",
  "pot. otros cultivos 90": "пот. другие культуры 90",
  "potasio foliar": "Фолярный калий",
  "potencial productivo histórico (ndvi)": "Исторический производственный потенциал (NDVI)",
  "precio": "Цена",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Предопределенные диапазоны от HEMAV, которые обычно подходят для большинства полей",
  "prediction": "Прогноз",
  "preprocessing": "Предварительная обработка",
  "Press this button to add a new field.": "Нажмите эту кнопку, чтобы добавить новое поле.",
  "Pressing this button lets you go back to the field view. ": "Нажав эту кнопку, вы можете вернуться к просмотру поля.",
  "Preview": "Предпросмотр",
  "price Kg": "Цена за кг",
  "primer vuelo": "Первый полет",
  "PROD": "Урожайность по единице площади",
  "producción esperada": "Ожидаемая урожайность",
  "product Kg": "Продукт кг",
  "product Kg is mandatory": "Продукт кг обязателен",
  "Production estimate": "Оценка производства",
  "production estimate (units)": "Оценка производства (Кг/Га)",
  "profile": "Профиль",
  "protein": "Белок",
  "puccinia_sp_g1": "puccinia sp \"ржавчина\" - g1",
  "puccinia_sp_g2": "puccinia sp \"ржавчина\" - g2",
  "puccinia_sp_g3": "puccinia sp \"ржавчина\" - g3",
  "puccinia_sp_g4": "puccinia sp \"ржавчина\" - g4",
  "puccinia_sp_g5": "puccinia sp \"ржавчина\" - g5",
  "Quick start with Azure": "Быстрый старт с Azure",
  "Random fertilization": "Случайное удобрение",
  "Random seedling": "Случайная посадка",
  "Range": "Диапазон",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Диапазоны одинакового размера будут сгенерированы в соответствии с минимальными и максимальными значениями изображения. В случаях, когда изображение очень однородное, вероятно, не будет видно различий.",
  "realProduction": "Реальное производство",
  "Recipients": "Получатели",
  "recomendación abonado dosificado": "Рекомендация дозирования удобрения",
  "recomendación abonado zonificado": "Рекомендация по зонированному удобрению",
  "Recomendacion de riego": "Рекомендация по орошению",
  "recomendación riego": "Рекомендация по орошению",
  "redo": "пересдать",
  "Redo Action": "Повторить действие",
  "Related date": "Связанная дата",
  "relative nitrogen": "Относительный азот",
  "Rendimiento graso ( % )": "Производительность жира ( % )",
  "report": "Отчет",
  "Report sent correctly": "Отчет успешно отправлен",
  "report type": "Тип",
  "Report type": "Тип отчета",
  "report_type_action_description": "Предложение лечения или действий, которые следует применить",
  "report_type_annotation_description": "Диагностика: анализ на месте",
  "report_type_inspection_description": "Анализ с помощью слоев (закрывается после анализа)",
  "report_type_result_description": "Анализ результатов примененного решения или лечения.",
  "reporte agronómico": "Агрономический отчет",
  "Reported by": "Сообщено",
  "Reported fields": "Сообщенные поля",
  "reports manager": "Отчеты",
  "required": "обязательно",
  "Required": "Обязательно",
  "Requires 3 characters minimun": "Требуется минимум 3 символа",
  "Reset": "Сбросить",
  "resiembra": "Пересев",
  "resolution": "Разрешение СГП (м)",
  "resolution (only SHP)": "Разрешение (только для SHP)",
  "retención de agua": "Удержание воды",
  "retry": "повторить",
  "rgb": "RGB",
  "richness": "Богатство",
  "riego zonificado": "Зонированное орошение",
  "riesgo malherbología": "Риск сорняков",
  "Ruler": "Линейка",
  "SAC": "Сахароза на единицу площади",
  "saccharose estimate": "Оценка сахарозы",
  "sample": "Образец",
  "sample type": "Тип",
  "Sample was deleted.": "Образец был удален.",
  "Sample was successfully modified.": "Образец был успешно изменен.",
  "sample_creation_date": "Дата создания",
  "samples": "Образцы",
  "satélite": "Спутник",
  "Satellite button tutorial": "Учебник: вид со спутника",
  "Satellite map": "Спутниковая карта",
  "Satellite map 2": "Спутниковая карта 2",
  "Satellite View": "Вид со спутника",
  "save": "Сохранить",
  "Save All": "Сохранить всё",
  "Save field": "Сохранить поле",
  "save_": "сохранить",
  "search": "Поиск",
  "Search": "Поиск",
  "season": "сезон",
  "Season label": "Метка сезона",
  "season name": "Название сезона",
  "SEASON_EVOLUTION": "Эволюция сезона",
  "Sector": "Сектор",
  "sectores target": "Целевые секторы",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Видишь? Теперь, нажимая кнопку снова, можно вернуться к виду со спутника.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Второй полет",
  "Select": "Выбрать",
  "select a crop and sample": "Выберите культуру и образец",
  "select all": "Выбрать все",
  "Select all": "Выбрать все",
  "select at least one field": "Выберите хотя бы одно поле",
  "select fields by": "Выберите поля по",
  "select fields by variability range": "выберите поля по диапазону изменчивости",
  "select fields to open temporal comparison": "Выберите поля для открытия временного сравнения",
  "select one": "Выбрать один",
  "select one customer please": "Выберите одного клиента, пожалуйста",
  "select one subtype please": "Выберите один подтип, пожалуйста",
  "select one type please": "Выберите тип культуры, пожалуйста",
  "select sample base layer and date": "выберите базовый слой образца и дату",
  "select sample location": "выберите местоположение образца",
  "select season": "выбрать сезон",
  "selected": "выбрано",
  "Selected fields names": "Названия выбранных полей",
  "Selected fields samples": "Образцы выбранных полей",
  "Selection includes too much information, try selecting a shorter date range": "Выбор включает слишком много информации, попробуйте выбрать более короткий диапазон дат",
  "Send": "Отправить",
  "Send report": "Отправить отчет",
  "settings": "Настройки",
  "Share fields report": "Поделиться отчетом по полям",
  "show all": "показать все",
  "Show All Layers": "Показать все слои",
  "Show current season only": "Показать только текущий сезон",
  "show fields with no data": "Показать поля без данных",
  "show filter list": "Показать список фильтров",
  "Show Global Layer": "Показать глобальный слой",
  "Show Measure Tools": "Показать инструменты измерения",
  "shp-tooltip": "Эти числа могут быть изменены в зависимости от потребностей использования и связаны с каждым классом геометрии в экспортируемой таблице атрибутов SHP. Обычно регулируются для установления количества удобрений или семян, которые будут применяться на единицу площади.",
  "singular selected": "выбрано",
  "Slice tool only works on Polygons and Circle": "Инструмент разреза работает только с полигонами и кругом",
  "Slider Tool": "Инструмент ползунка",
  "soil": "Почва",
  "soil_type": "Тип почвы",
  "sólo para la generación de SHP": "Только для генерации SHP",
  "Sort": "Сортировка",
  "sort by date": "сортировать по дате",
  "Start date": "Дата начала",
  "static layers": "статические слои",
  "Statistics": "Статистика",
  "status": "Статус",
  "stem_num": "Количество стеблей",
  "Streets distance": "Расстояние до улиц",
  "Subtype": "Подтип",
  "Suc/Ha": "Сах/Га",
  "sum_of_bases": "Сумма оснований",
  "Superficie ( Ha )": "Площадь ( Га )",
  "Superficie Sector ( Ha )": "Площадь сектора ( Га )",
  "surfaceUnit": "Единица площади",
  "switch between all samples and current season samples": "переключаться между всеми образцами и образцами текущего сезона",
  "Switch to hybrid map": "Переключиться на гибридную карту",
  "Switch to Mapbox": "Переключиться на карту спутник2",
  "Switch to satellite map": "Переключиться на спутниковую карту",
  "Switch to Terrain map": "Переключиться на топографическую карту",
  "target date": "Целевая дата",
  "target multiparcela": "Целевое многополье",
  "tell us": "Расскажите нам",
  "Temporal Comparison tutorial": "Учебник: Временное сравнение",
  "Terrain map": "Карта местности",
  "textura del suelo": "Текстура почвы",
  "The date should be in DD/MM/YYYY format": "Дата должна быть в формате ДД/ММ/ГГГГ",
  "The distance must be in meters": "Расстояние должно быть в метрах",
  "the extra parameters will be removed": "дополнительные параметры будут удалены",
  "the following layers are no longer available in offline mode, download again if necessary": "следующие слои больше недоступны в автономном режиме, при необходимости скачайте заново",
  "The production must be numeric/decimal": "Производство должно быть числовым/десятичным",
  "The sample location must be inside one of the fields.": "Местоположение образца должно находиться внутри одного из полей.",
  "There are no samples to download": "Нет образцов для скачивания",
  "There is no operated flight on this field.": "На этом поле не было полета",
  "Thermal Accumulation": "Тепловое накопление",
  "This action will delete the field and all its related data": "Это действие удалит поле и все связанные с ним данные",
  "this action will modify your field selection": "это действие изменит ваш выбор полей",
  "this action will open field view and lose current field selection": "это действие откроет вид поля и отменит текущий выбор поля",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Эта кнопка позволяет рисовать линии или полигоны для измерения расстояния или площади.",
  "This button allows you to switch to the hybrid view.": "Эта кнопка позволяет переключиться на гибридный вид.",
  "This can take a long time": "Это может занять много времени",
  "This field is not downloaded.": "Это поле не загружено",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Этот инструмент позволяет резать полигоны. Используйте одиночные щелчки для рисования линии и двойной щелчок для завершения рисования.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Этот инструмент позволяет рисовать полигоны. Попробуйте нарисовать простой. Убедитесь, что вы закрыли его!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Этот инструмент позволяет измерять расстояние между набором точек. Используйте одиночные щелчки, чтобы установить точки, и двойной щелчок, чтобы завершить рисование.",
  "Those fields are mandatory": "Эти поля обязательны",
  "TIME_COMPARISON_DROP_ZONE": "Перетащите сюда...",
  "TINTA BARROCA": "БАРОЧНЫЕ ЧЕРНИЛА",
  "TINTA FRANCISCA": "ФРАНЦИСКАНСКИЕ ЧЕРНИЛА",
  "TINTO": "КРАСНЫЙ",
  "TINTO CÃO": "КРАСНЫЙ CAO",
  "tipo de dosificacion": "Тип дозировки",
  "to": "До",
  "today": "сегодня",
  "todos": "Все",
  "tons of cane - annual vision": "Тонны сахарного тростника - годовой обзор",
  "Total": "Итого",
  "total production": "Общая продукция (Кг)",
  "total_fruits_bigSize": "Всего больших фруктов",
  "total_fruits_mediumSize": "Всего средних фруктов",
  "total_fruits_smallSize": "Всего мелких фруктов",
  "total_weight": "Общий вес",
  "total_weight_bigSize": "Общий вес больших фруктов",
  "total_weight_canes": "Общий вес тростника",
  "total_weight_mediumSize": "Общий вес средних фруктов",
  "total_weight_smallSize": "Общий вес мелких фруктов",
  "TOURIGA FRANCA": "ТУРИГА ФРАНКА",
  "training": "Обучение",
  "TRINCADEIRA": "ТРИНКАДЕЙРА",
  "Try our mobile app for a better experience": "Попробуйте наше мобильное приложение для лучшего опыта",
  "type": "Культура",
  "Type": "Тип культуры",
  "type here your notes": "введите здесь свои заметки",
  "Type your text": "Введите свой текст",
  "type_sampler": "Тип выборки",
  "undo": "отменить",
  "Undo Action": "Отменить действие",
  "unidades": "Единицы",
  "unitSystem": "Система единиц",
  "Unknown": "Неизвестно",
  "unnamed field": "Поле без имени",
  "unsaved changes will be lost": "несохраненные изменения будут потеряны",
  "update sample": "обновить образец",
  "update_draft": "обновить черновик",
  "updated": "Обновлено",
  "Use this buttons to select which layer to compare.": "Используйте эту кнопку для выбора слоя для сравнения.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Используйте этот инструмент для временного сравнения двух полетов. Чтобы начать, перетащите один из полетов на тот, с которым хотите сравнить.",
  "Use this tool to freely move the created polygons.": "Используйте этот инструмент для свободного перемещения созданных полигонов.",
  "use_layers_web_to_create_large_fields": "Используйте Layers Web для создания полей более 10 000 га",
  "used": "использовано",
  "Username": "Имя пользователя",
  "users": "Пользователи",
  "Value": "Значение",
  "variabilidad multiparcela": "Многопольная изменчивость",
  "variabilidad parcela (vra)": "Изменяемая норма (VRA)",
  "variability": "Изменчивость",
  "Variedad": "Подкультура",
  "vegetative growth": "Вегетативный рост",
  "vegetative growth per day": "Накопленный вегетативный рост за день",
  "vegetative growth variability": "Изменчивость вегетативного роста",
  "Ver detalles": "Просмотреть детали",
  "very high": "Очень высокий",
  "very high level": "Очень высокий",
  "very low": "Очень низкий",
  "very low level": "Очень низкий",
  "view": "просмотр",
  "vigor": "СИЛА",
  "vigor a nivel árbol": "сила / растение",
  "vigor acumulado dos meses (ndvi)": "Накопленная сила два месяца (NDVI)",
  "vigor acumulado un año (ndvi)": "Накопленная сила один год (NDVI)",
  "vigor vegetativo": "Вегетативная сила",
  "vigor vegetativo - pcd": "PCD - Вегетативная сила",
  "vigor vegetativo (ndvi)": "Вегетативная сила (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Вегетативная сила (NDVI) - Ранние стадии",
  "vigor zonificado": "Зонированная сила",
  "VINHÃO": "ВИНЬЯО",
  "Visibility": "Видимость",
  "Vulnerable area": "Уязвимая зона",
  "wait": "ждать",
  "warning": "Предупреждение",
  "warning map": "Карта предупреждений",
  "Water level": "Уровень воды",
  "water stress": "Водный стресс",
  "We are processing the data for your flight. Results will be available soon.": "Мы обрабатываем данные вашего полета. Результаты будут доступны скоро.",
  "weeds area": "Область сорняков",
  "week": "Неделя",
  "weight_1": "Вес 1",
  "weight_2": "Вес 2",
  "weight_3": "Вес 3",
  "weight_4": "Вес 4",
  "weight_5": "Вес 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "С помощью этого инструмента вы можете рисовать круги. Щелкните, чтобы установить центр, и щелкните еще раз, чтобы выбрать радиус.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "С помощью этого инструмента вы можете нарисовать полигон с 3 или более точками для измерения его площади.",
  "without warning": "Без предупреждений",
  "Wrong credentials": "Неверные учетные данные",
  "year": "Год",
  "years": "Год",
  "You can change here the information of the new field.": "Вы можете изменить здесь информацию о новом поле.",
  "You can move this bar to compare the layers temorally.": "Используйте этот инструмент для временного сравнения слоев.",
  "You can only compare dates from the same sources": "Вы можете сравнивать только даты из одних и тех же источников",
  "You can use this button at any moment to undo your last actions.": "Вы можете использовать эту кнопку в любое время, чтобы отменить свои последние действия.",
  "You can use this slider to change the opacity of the selected layer.": "Вы можете использовать этот ползунок, чтобы изменить непрозрачность выбранного слоя.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Вы не можете создать поле, так как у вас нет клиентов. Пожалуйста, попросите нас добавить их.",
  "You must enter a decimal": "Вы должны ввести десятичное число",
  "You must enter an integer": "Вы должны ввести целое число",
  "You must select an irrigation type": "Вы должны выбрать тип орошения",
  "You must type a field name": "Вы должны ввести название поля",
  "You must type a text": "Вы должны ввести текст",
  "Your field has been successfully deleted.": "Ваше поле было успешно удалено",
  "Your LAYERS version has expired. Click to update.": "Ваша версия LAYERS устарела. Нажмите для обновления."
}
