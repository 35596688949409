import React from 'react'
import styled, { css } from 'styled-components'
import { ListItemButton } from '@mui/material'
import { UIStyles } from '../../theme'
import { rotate } from './buttons'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { StyledTooltip } from './StyledTooltip'

export const Tooltip = styled(StyledTooltip)`
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
`

const defaultListItemSx = {
  marginLeft: 0,
  padding: '16px',
  position: 'relative',
  cursor: 'pointer',
  justifyContent: 'center',
  color: UIStyles.vomitColor,
  ':hover': {
    backgroundColor: UIStyles.vomitLightHoverTransparent,
    opacity: 0.9
  }
}
const activeListItemSx = {
  backgroundColor: UIStyles.vomitLightHover
}

const Icon = styled.i`
  position: relative;
  font-size: ${props => (props.loading ? '0' : '24px')};
  color: ${({ disabled }) => (disabled ? themeColors.whiteColor : themeColors.vomitColor)};
  ${props =>
    props.iconAfter &&
    css`
      &::after {
        content: '\f067';
        font-size: 10px;
        position: absolute;
        bottom: -10px;
        right: -10px;
        color: ${({ disabled }) => (disabled ? themeColors.lightGreySolid : themeColors.blueColor)};
        border-radius: 50%;
        background: ${({ disabled }) => (disabled ? themeColors.whiteColor : themeColors.vomitColor)};
        padding: 0.3rem;
        border: 1px solid;
        display: flex;
        justify-content: center;
        flex-direction: row;
      }
    `}

  ${props =>
    props.loading &&
    css`
      &:after {
        content: '';
        display: ${props => (props.loading ? 'inline-block' : 'none')};
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: ${UIStyles.vomitColor};
        animation: ${rotate} 1.5s ease-in-out infinite;
      }
    `}
`

const StyledListButton = ({
  value,
  disabled,
  label,
  onClick,
  icon,
  isActiveListItem,
  labelDistance,
  iconAfter = false,
  loading = false,
  hidden,
  itemStyle = {},
  testId,
  disableTooltip = false
}) => {
  if (hidden) return null

  const Wrapper = disableTooltip ? ({ children }) => children : Tooltip

  return (
    <Wrapper disabled={disabled} title={label} placement="right" style={itemStyle}>
      <ListItemButton
        key={value}
        onClick={onClick}
        value={value}
        style={{ fontSize: 25 }}
        sx={[defaultListItemSx, isActiveListItem && activeListItemSx]}
        disabled={disabled}
      >
        <Icon className={`${icon}`} disabled={disabled} iconAfter={iconAfter} loading={loading} data-testid={testId} />
      </ListItemButton>
    </Wrapper>
  )
}
export default StyledListButton
