import get from 'lodash/get'
import reduce from 'lodash/reduce'
import each from 'lodash/each'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import replace from 'lodash/replace'
import find from 'lodash/find'
import toString from 'lodash/toString'
import moment from 'moment'

import GeometryUtils from './geometryUtils'
import { s3PathToTilerUrl } from './tilerUtils'
import { getToken } from './userStoredData'
import { DRONE_RGB_SNAPSHOT_SOURCE, DRONE_SNAPSHOT_SOURCE, PLANET_SNAPSHOT_SOURCE } from '@layers-frontend/commons/constants'

export const builGroupdDeliverablesFromProps = props =>
  map(props.flightGroupDeliverables, flightGroupDeliverable => prepareDeliverableUrl(flightGroupDeliverable, props))

const buildSHLayerGroup = (deliverable, deliverableUrl, fieldGeometry, source) => {
  const fieldGeojsonGeometries = GeometryUtils.multipolygonToGeometries(fieldGeometry)
  const fieldWktGeometries = fieldGeojsonGeometries.map(fieldGeojson => GeometryUtils.geojsonToWkt(fieldGeojson.geometry, 2, 0))
  return fieldWktGeometries.map((_, index) => {
    const geometry = fieldGeojsonGeometries[index].geometry

    return {
      // eslint-disable-next-line object-shorthand
      deliverable: deliverable,
      url: GeometryUtils.addGeometryToSentinelUrl(deliverableUrl, geometry),
      extent: GeometryUtils.geometryToBbox(fieldGeojsonGeometries[index].geometry),
      // eslint-disable-next-line object-shorthand
      source: source
    }
  })
}

const prepareDeliverableUrl = (flightGroupDeliverable, props) => {
  const userToken = getToken()
  const { selectedFlightGroup, flightGroupSnapshots, flightSource, flightGroupSeasons, baseEvalscriptUrl, sentinelApiKey, planetApiKeys } = props
  const flights = get(selectedFlightGroup, 'flights')
  const layerGroupData = reduce(
    flights,
    (accumulator, flight) => {
      const flightDate = get(flight, 'date')
      const flightSeasonsIds = map(get(flight, 'seasons'), 'id')
      const flightSeasons = filter(flightGroupSeasons, season => {
        return includes(flightSeasonsIds, toString(get(season, 'id')))
      })
      const flightDeliverables = []
      each(flightSeasons, season => {
        const geometry = get(season, 'geometry')
        const seasonId = get(season, 'id')

        switch (flightSource) {
          case DRONE_RGB_SNAPSHOT_SOURCE:
          case DRONE_SNAPSHOT_SOURCE:
            // map all deliverables from flightGroupSnapshots
            each(flightGroupSnapshots, snapshot => {
              const deliverableToAdd = find(
                get(snapshot, ['deliverables', seasonId, 'deliverables']),
                seasonDeliverable => get(seasonDeliverable, 'typeId') === get(flightGroupDeliverable, 'typeId')
              )
              if (deliverableToAdd) {
                // build a layer for each deliverable to add
                const s3Path = get(deliverableToAdd, 's3Path')
                flightDeliverables.push({
                  deliverable: flightGroupDeliverable,
                  url: s3PathToTilerUrl(s3Path),
                  extent: GeometryUtils.geometryToBbox(geometry),
                  s3Path,
                  source: flightSource
                })
              }
            })

            break

          default:
            // MAP ALL SH DELIVERABLES
            /* eslint-disable no-case-declarations */
            const seasonDeliverables = get(season, ['deliverables', flightSource, 'deliverables'])
            const deliverableToAdd = find(seasonDeliverables, seasonDeliverable => get(seasonDeliverable, 'typeId') === get(flightGroupDeliverable, 'typeId'))
            if (!deliverableToAdd) {
              return
            }
            let deliverableUrl = get(deliverableToAdd, 'geoserverurl')
            // add flightdate to url
            const intervalDays = get(deliverableToAdd, ['type', 'intervalDays'])
            const startDate = intervalDays ? moment(flightDate).subtract(intervalDays, 'days').format('YYYY-MM-DD') : flightDate
            const endDate = flightDate
            /* eslint-enable no-case-declarations */
            /* eslint-disable no-template-curly-in-string */
            deliverableUrl = replace(deliverableUrl, '${END_DATE}', `${startDate}/${endDate}`)

            switch (flightSource) {
              case PLANET_SNAPSHOT_SOURCE:
                if (planetApiKeys && planetApiKeys[seasonId]) {
                  deliverableUrl = replace(deliverableUrl, '${API_KEY}', planetApiKeys[seasonId])
                }
                break

              default:
                deliverableUrl = replace(deliverableUrl, '${API_KEY}', sentinelApiKey)
                break
            }

            // add api key

            if (deliverableUrl.includes('evalscripturl')) {
              deliverableUrl = replace(deliverableUrl, '${BASE_EVALSCRIPT_URL}', baseEvalscriptUrl)
              deliverableUrl = replace(deliverableUrl, '${FIELD_ID}', get(season, 'fieldId'))
              deliverableUrl = replace(deliverableUrl, '${USER_TOKEN}', userToken)
              deliverableUrl = replace(deliverableUrl, '${FLIGHT_DATE}', flightDate)
            }
            /* eslint-enable no-template-curly-in-string */

            // eslint-disable-next-line no-case-declarations
            const layerGroup = buildSHLayerGroup(flightGroupDeliverable, deliverableUrl, geometry, flightSource)
            flightDeliverables.push(...layerGroup)
            break
        }
      })
      return [...accumulator, ...flightDeliverables]
    },
    []
  )

  return layerGroupData
}
