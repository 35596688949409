import { SELECT_SEARCH_ITEM } from '../actions/search'
import { SELECT_TEMPORAL_SNAPSHOT, SELECT_SNAPSHOT, ADD_FLIGHT_GROUPS, FLUSH_FIELDS_GROUP } from '../actions/fields'

import {
  OPEN_CREATION_MODE,
  CLOSE_CREATION_MODE,
  OPEN_EDIT_MODE,
  CLOSE_EDIT_MODE,
  OPEN_SEARCH_MODE,
  CLOSE_TEMPORAL_COMPARE_MODE,
  OPEN_SAMPLE_LOCATION_MODE,
  SAMPLE_LOCATION_VIEW,
  SEARCH_VIEW,
  FIELDS_LIST_VIEW,
  FIELD_VIEW,
  FIELD_NEW_VIEW,
  FIELD_EDIT_VIEW,
  TEMPORAL_COMPARE_VIEW,
  LOADING_PAGE_VIEW,
  RECEIVE_VIEWER_DATA,
  TOGGLE_BASE_MAP,
  SELECT_BASE_MAP,
  SELECT_BASE_MAP_BY_TYPE
} from '../actions/viewmode'
import { SET_TEMPORAL_COMPARISON_DATE } from '../actions/calendar'
import { CONFIRM_SAMPLE_FORM_LOCATION } from '../actions/samplesForm'
import { DENY_SAMPLE_LOCATION, REQUEST_FAIL } from '@layers-frontend/commons/store/storeConstants'
import { REQUEST_EOX_CAPABILITIES } from '@layers-frontend/commons/constants'

export const screenView = (state = LOADING_PAGE_VIEW, action) => {
  switch (action.type) {
    case SELECT_SEARCH_ITEM:
      return FIELDS_LIST_VIEW
    case CLOSE_CREATION_MODE:
    case RECEIVE_VIEWER_DATA:
      return SEARCH_VIEW
    case FLUSH_FIELDS_GROUP:
    case OPEN_SEARCH_MODE:
      return SEARCH_VIEW
    case ADD_FLIGHT_GROUPS:
      if (state === SEARCH_VIEW) {
        return FIELD_VIEW
      }
      return state
    case OPEN_CREATION_MODE:
      return FIELD_NEW_VIEW
    case OPEN_EDIT_MODE:
      return FIELD_EDIT_VIEW
    case OPEN_SAMPLE_LOCATION_MODE:
      return SAMPLE_LOCATION_VIEW
    case SELECT_TEMPORAL_SNAPSHOT:
    case SET_TEMPORAL_COMPARISON_DATE:
      return TEMPORAL_COMPARE_VIEW
    case CLOSE_TEMPORAL_COMPARE_MODE:
    case CLOSE_EDIT_MODE:
    case SELECT_SNAPSHOT:
    case CONFIRM_SAMPLE_FORM_LOCATION:
    case DENY_SAMPLE_LOCATION:
      return FIELD_VIEW
    default:
      return state
  }
}

const initialSatelliteState = [
  {
    type: 'PNOA',
    icon: 'fa-solid fa-earth-americas',
    selected: true,
    label: 'Satellite map',
    sources: [
      {
        provider: 'EOX',
        attributions: 'Sentinel-2 cloudless - https://s2maps.eu by EOX IT Services GmbH (Contains modified Copernicus Sentinel data 2024)',
        sourceType: 'OlSourceWMTS',
        layer: 's2cloudless-2024_3857'
      },
      {
        url: 'https://www.ign.es/wms-inspire/pnoa-ma', // map for Spain
        provider: 'pnoa',
        attributions: '<a href="http://www.ign.es" target="_blank">IGN.es</a>',
        sourceType: 'OlSourceWMS',
        layers: 'OI.OrthoimageCoverage'
      }
    ]
  },
  {
    type: '3D',
    icon: 'ion-map',
    selected: false,
    label: 'Terrain map',
    sources: [
      {
        url: `${window.location.origin}/maps/styles/v1/mapbox/outdoors-v11/tiles/256/{z}/{x}/{y}@2x`,
        provider: 'mapbox',
        attributions: '© Mapbox, Inc',
        sourceType: 'OlSourceXYZ'
      }
    ]
  },
  {
    type: 'streets',
    icon: 'ion-android-map',
    selected: false,
    label: 'Hybrid map',
    sources: [
      {
        url: `${window.location.origin}/maps/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}@2x`,
        provider: 'mapbox',
        attributions: '© Mapbox, Inc',
        sourceType: 'OlSourceXYZ'
      }
    ]
  },
  {
    type: 'satellite',
    icon: 'ion-earth',
    selected: false,
    label: 'Satellite map 2',
    sources: [
      {
        url: `${window.location.origin}/maps/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x`,
        provider: 'mapbox',
        attributions: '© Mapbox, Inc',
        sourceType: 'OlSourceXYZ'
      }
    ]
  },
  {
    type: 'dark',
    icon: 'ion-contrast',
    selected: false,
    label: 'High contrast map',
    sources: [
      {
        url: `${window.location.origin}/maps/styles/v1/mapbox/dark-v11/tiles/256/{z}/{x}/{y}@2x`,
        provider: 'mapbox',
        attributions: '© Mapbox, Inc',
        sourceType: 'OlSourceXYZ'
      }
    ]
  }
]

export const SELECT_SPECIFIC_BASE_MAP = 'SELECT_SPECIFIC_BASE_MAP'

export const baseMaps = (state = initialSatelliteState, action) => {
  switch (action.type) {
    case TOGGLE_BASE_MAP:
      return state.map((baseMap, index, baseMaps) => ({
        ...baseMap,
        selected: index === (baseMaps.findIndex(baseMap => baseMap.selected) + 1) % baseMaps.length
      }))
    case SELECT_BASE_MAP: {
      const selectedIndex = action.localStorageIndex
      return state.map((baseMap, index, baseMaps) => ({
        ...baseMap,
        selected: index === selectedIndex % baseMaps.length
      }))
    }

    case SELECT_BASE_MAP_BY_TYPE:
      return state.map(baseMap => ({
        ...baseMap,
        selected: baseMap.type === action.mapType
      }))

    case REQUEST_FAIL:
      if (action.requestType === REQUEST_EOX_CAPABILITIES) {
        const withoutEox = state.slice(1)

        return withoutEox.map((baseMap, index, baseMaps) => ({
          ...baseMap,
          selected: index === baseMaps.findIndex(baseMap => baseMap.label === 'Satellite map 2')
        }))
      }
      return state
    default:
      return state
  }
}
