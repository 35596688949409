import React, { useRef, useEffect } from 'react'
import { select, scaleLinear, scaleBand, axisLeft, axisBottom, format } from 'd3'

/**
 * @typedef {Object} Class
 * @property {string} color - The color associated with the class.
 * @property {string} class - The name or category of the class.
 * @property {number} value - The numerical value representing the class.
 * @property {number} fraction - The fraction (between 0 and 1) representing the portion of the total.
 * @property {number} surface - The surface area or value associated with the class.
 */

/**
 * BarChart component renders a vertical bar chart using the classes array.
 *
 * @param {Object} props
 * @param {Class[]} props.classes - Array of class objects.
 * @returns {JSX.Element} The rendered SVG element of the bar chart.
 */

const BarChart = ({ classes, t }) => {
  const svgRef = useRef()

  useEffect(() => {
    const svgElement = svgRef.current
    const margin = { top: 10, right: 10, bottom: 25, left: 40 }
    const width = 300 - margin.left - margin.right
    const height = 130 - margin.top - margin.bottom

    const container = select(svgElement)
    container.selectAll('*').remove()

    const svg = container
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    svg.append('rect').attr('width', width).attr('height', height).attr('fill', 'rgba(255, 255, 255, 0.1)').attr('x', 0).attr('x', 0).attr('y', 0)

    const xScale = scaleBand()
      .domain(classes.map(d => d.class))
      .range([0, width])
      .padding(0.5)

    const yScale = scaleLinear().domain([0, 1]).range([height, 0])

    // Add a tooltip div
    const tooltip = select('body')
      .append('div')
      .attr('class', 'bar-chart-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'rgba(0, 0, 0, 0.6)')
      .style('color', 'white')
      .style('padding', '5px')
      .style('border-radius', '4px')
      .style('font-size', '9px')
      .style('z-index', '10')
      .style('display', 'flex')
      .style('align-items', 'center')

    // tooltip.append('div').attr('class', 'color-indicator').style('width', '20px').style('height', '20px').style('border-radius', '2px')

    tooltip.append('div').attr('class', 'tooltip-text')

    const handleMouseOver = (event, d) => {
      tooltip
        .style('visibility', 'visible')
        .style('left', `${event.pageX + 10}px`)
        .style('top', `${event.pageY - 10}px`)
      tooltip.select('.tooltip-text').text(`${t(d.class)}: ${format('.1%')(d.fraction)}`)
    }

    const handleMouseMove = event => {
      tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 10}px`)
    }

    const handleMouseOut = () => {
      tooltip.style('visibility', 'hidden')
    }

    svg.selectAll('.bar-group').data(classes)
    svg
      .selectAll('.bar-rect')
      .data(classes)
      .enter()
      .append('rect')
      .attr('class', 'bar-rect')
      .attr('x', d => xScale(d.class))
      .attr('y', d => yScale(d.fraction))
      .attr('width', xScale.bandwidth())
      .attr('height', d => yScale(0) - yScale(d.fraction))
      .attr('fill', d => d.color)
      .on('mouseover', handleMouseOver)
      .on('mousemove', handleMouseMove)
      .on('mouseout', handleMouseOut)
    svg
      .selectAll('.bar-hover-area')
      .data(classes)
      .enter()
      .append('rect')
      .attr('class', 'bar-hover-area')
      .attr('x', d => xScale(d.class))
      .attr('y', 0)
      .attr('width', xScale.bandwidth())
      .attr('height', height)
      .attr('fill', 'transparent')
      .style('pointer-events', 'all')
      .on('mouseover', function (event, d) {
        select(this).attr('fill', 'rgba(255, 255, 255, 0.1)')
        handleMouseOver(event, d)
      })
      .on('mousemove', function (event, d) {
        select(this).attr('fill', 'rgba(255, 255, 255, 0.1)')
        handleMouseMove(event, d)
      })
      .on('mouseout', function (event, d) {
        select(this).attr('fill', 'transparent')
        handleMouseOut(event, d)
      })

    const xAxis = svg.append('g').attr('transform', `translate(0,${height})`).attr('color', '#26637f').call(axisBottom(xScale))

    xAxis
      .selectAll('text')
      .data(classes)
      .text(d => t(d.class))
      .attr('fill', 'transparent')
      .attr('font-size', '9px')
      .style('text-anchor', 'middle')
      .attr('x', (d, i) => xScale(i))

    const yTicks = [0, 0.2, 0.4, 0.6, 0.8, 1]
    const yAxis = svg
      .append('g')
      .attr('color', '#26637f')
      .call(
        axisLeft(yScale)
          .tickValues(yTicks.filter(value => value !== 1))
          .tickFormat(format('.0%'))
      )

    yAxis.selectAll('.domain, .tick line').attr('stroke', 'transparent')
    xAxis.selectAll('.domain, .tick line').attr('stroke', 'transparent')

    yAxis.selectAll('text').attr('fill', 'white').attr('font-size', '9px')

    // Add cleanup function to remove tooltip when component unmounts
    return () => {
      select('body').selectAll('.bar-chart-tooltip').remove()
    }
  }, [classes, t])

  return <svg ref={svgRef}></svg>
}

export default BarChart
