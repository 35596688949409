import React, { useState } from 'react'

import { withTranslator } from '../HOCComponents/withTranslator'
import StyledListButton from '../UIComponents/StyledListButton'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const List = styled.div`
  background-color: ${themeColors.blueColor};
  position: absolute;
  bottom: 10px;
  left: 70px;
  color: white;
  display: flex;
  flex-direction: column;
  width: ${props => (props.isOpen ? 'auto' : '0')};
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: ${props => (props.isOpen ? 1 : 0)};
`

const MapTypeButton = styled.button`
  background-color: transparent;
  padding: 10px 12px 10px 12px;
  border: none;
  width: auto;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &:hover {
    background-color: ${themeColors.vomitLightHover};
  }
`

const Icon = styled.i`
  position: relative;
  width: 20px;
  margin-right: 10px;
  color: ${({ disabled }) => (disabled ? themeColors.vomitColor : themeColors.whiteColor)};
  font-size: ${props => (props.loading ? '0' : '20px')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`

export const SatelliteButton = withTranslator(({ icon, label, selectBaseMapByType, t }) => {
  const baseMaps = useSelector(state => state.baseMaps)
  const [isOpen, setIsOpen] = useState(false)
  const handleMapChange = mapType => {
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_VIEW_STATISTICS, {
      button: label,
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
    selectBaseMapByType(mapType)
    setIsOpen(false)
  }

  return (
    <>
      <BlackDrop isVisible={isOpen} onClick={() => setIsOpen(false)} />
      <StyledListButton label={t(label)} onClick={() => setIsOpen(isOpen => !isOpen)} labelDistance={6} icon={icon} title="" disableTooltip={isOpen} />
      <List isOpen={isOpen}>
        {baseMaps.map(map => (
          <MapTypeButton
            disabled={baseMaps.find(map => map.selected).type === map.type}
            key={map.type}
            value={map.type}
            onClick={() => handleMapChange(map.type)}
          >
            <Icon className={map.icon} disabled={baseMaps.find(map => map.selected).type === map.type} />
            {t(map.label)}
          </MapTypeButton>
        ))}
      </List>
    </>
  )
})
