import {
  USER_ROLES,
  MAX_SENTINEL_URL_LENGTH,
  DEFAULT_LEGEND_TYPE,
  CATEGORICAL_COLORS,
  GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY,
  DAY_CYCLE_CAP
} from '@layers-frontend/commons/constants'
import moment from 'moment'

export { DAY_CYCLE_CAP, GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY, USER_ROLES, MAX_SENTINEL_URL_LENGTH, DEFAULT_LEGEND_TYPE, CATEGORICAL_COLORS }
export const isDevelopmentEnvironment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const shouldMeasurePerformance = process.env.MEASURE_PERFORMANCE

export const MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS = 50

export const SEASON_EVOLUTION_START_WEEK = 5
export const SEASON_EVOLUTION_END_WEEK = 60

export const FETCH_STATISTICS_DEBOUNCE_TIME = 500
export const FETCH_PREDTECH_DEBOUNCE_TIME = 1000

// VEGETATION_GROWTH_ADDEND ==>
// Here there is a study (page 3 - Mean sigma0 in dB) where we saw that the value is always below -20 but never arrives to -30, that is why we decided to add '30' so the users could feel more comfortable with this new measure
// https://gitlab.com/hemav-software/layers/uploads/1288777ecd58a26dfae25a61e53b729e/Linear_or_DB.pdf
// https://gitlab.com/hemav-software/layers/-/issues/2629
export const VEGETATION_GROWTH_ADDEND = 30

export const REQUEST_STATUS_PENDING = 'PENDING'
export const REQUEST_STATUS_SUCCESS = 'SUCCESS'
export const REQUEST_STATUS_FAIL = 'FAIL'

export const METADATA_DATE_RANGE = 90

export const MAX_CLOUD_COVERAGE = 100

const LAYERS_ROUTE = 'layers'
const DASHBOARD_ROUTE = 'dashboard'
const BILABS_ROUTE = 'bilabs'
const TOKEN_LOGIN_ROUTE = 'tokenLogin'
const LOG_OUT_ROUTE = 'logout'
const ACCOUNTS_ROUTE = 'accounts'
const LOGIN_ROUTE = 'login'

export const ROUTES = {
  LAYERS_ROUTE,
  DASHBOARD_ROUTE,
  TOKEN_LOGIN_ROUTE,
  LOG_OUT_ROUTE,
  BILABS_ROUTE,
  ACCOUNTS_ROUTE,
  LOGIN_ROUTE
}

export const FALLBACK_LANGUAGE = 'en'

const availableLanguages = ['de', 'en', 'es', 'fr', 'hu', 'it', 'pt', 'ro', 'ru', 'sk', 'sr', 'uk']

export const browserLanguage =
  [
    ...(window.navigator.languages || []),
    window.navigator.language,
    window.navigator.browserLanguage,
    window.navigator.userLanguage,
    window.navigator.systemLanguage
  ]
    .filter(Boolean)
    .map(language => language.substr(0, 2))
    .find(language => availableLanguages.includes(language)) || FALLBACK_LANGUAGE

export const validModalNames = {
  DOWNLOAD_STATISTICS_MODAL: 'DOWNLOAD_STATISTICS_MODAL',
  EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL: 'EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL',
  PLANTATION_DATE_FILTER_MODAL: 'PLANTATION_DATE_FILTER_MODAL',
  GO_TO_FIELD_MODAL: 'GO_TO_FIELD_MODAL',
  CONFIRM_CHANGE_FIELDS_SELECTION_MODAL: 'CONFIRM_CHANGE_FIELDS_SELECTION_MODAL',
  SEND_FIELDS_REPORT_MODAL: 'SEND_FIELDS_REPORT_MODAL',
  REPORT_MANAGER_MODAL: 'REPORT_MANAGER_MODAL',
  FILE_GENERATOR_MODAL: 'FILE_GENERATOR_MODAL',
  FIELD_SEASON_FORM_MODAL: 'FIELD_SEASON_FORM_MODAL',
  GLOBAL_FILES_MODAL: 'GLOBAL_FILES_MODAL'
}

export const DRONE_TYPE = '1'
export const SATELLITE_TYPE = '3'
export const PLANE_SOURCE_TYPE_1 = '4'
export const PLANE_SOURCE_TYPE_2 = '5'
export const DRONE_RGB_TYPE = '6'
export const RADAR_TYPE = '7'
export const RADAR_DESC_TYPE = '9'
export const PLANET_TYPE = '10'
export const L9_TYPE = '11'

export const layersViews = {
  SEARCH_VIEW: 'SEARCH_VIEW',
  SEARCH_DELETED_VIEW: 'SEARCH_DELETED_VIEW',
  FIELDS_LIST_VIEW: 'FIELDS_LIST_VIEW',
  FIELD_VIEW: 'FIELD_VIEW',
  FIELD_NEW_VIEW: 'FIELD_NEW_VIEW',
  FIELD_EDIT_VIEW: 'FIELD_EDIT_VIEW',
  AREA_MEASURE_VIEW: 'AREA_MEASURE_VIEW',
  LINE_MEASURE_VIEW: 'LINE_MEASURE_VIEW',
  TEMPORAL_COMPARE_VIEW: 'TEMPORAL_COMPARE_VIEW',
  LOADING_PAGE_VIEW: 'LOADING_PAGE_VIEW',
  SAMPLE_LOCATION_VIEW: 'SAMPLE_LOCATION_VIEW'
}

export const dashboardViews = {
  FLIGHT_FILTER: 'FLIGHT_FILTER',
  FIELD_FILTER: 'FIELD_FILTER',
  FIELD_TEMPORAL_EVOLUTION: 'FIELD_TEMPORAL_EVOLUTION',
  HARVEST_PLANNING_TOOL: 'HARVEST_PLANNING_TOOL',
  METEOROLOGICAL_STATISTICS: 'METEOROLOGICAL_STATISTICS',
  PRED_TECH: 'PRED_TECH'
}

export const advancedSearch = {
  PLANTATION_RANGE_FILTER: 'plantationDateRange',
  TYPE_FILTER: 'crops',
  CUSTOMER_FILTER: 'customers',
  SEARCH_INPUT_FILTER: 'searchInput',
  NAME_FILTER: 'fields',
  CUT_NUMBER_FILTER: 'cutNumber',
  SEASON_LABEL_FILTER: 'seasonLabel'
}

export const plantsMetadataKeys = {
  ATR: 'atr',
  POL: 'pol',
  ATR_POL: 'atr_pol',
  DRY_MATTER: 'dryMatterLaboratory'
}

export const deliverablesFormResolutionOptions = [
  {
    name: '5',
    id: 5
  },
  {
    name: '10',
    id: 10
  },
  {
    name: '20',
    id: 20
  },
  {
    name: '50',
    id: 50
  }
]

export const NEW_SEASON_START_DATE = moment().subtract(4, 'years').format('YYYY-MM-DD')
export const NEW_SEASON_END_DATE = moment().endOf('year').add(2, 'years').format('YYYY-MM-DD')

export const SEASON_PARAMETERS = {
  PRODUCTION: 1,
  PLANTATION_DATE: 2,
  PATTERN: 3,
  PLANTATION_FRAME: 4,
  PLANT_DISTANCE: 5,
  STREET_DISTANCE: 6,
  RANDOM_SEEDLING: 7,
  RANDOM_FERTILIZATION: 8,
  AUTO_HARVEST: 9,
  IRRIGATION_EMITTERS: 10,
  IRRIGATION_LIMIT: 11,
  IRRIGATION_LEVEL: 12,
  VULNERABILITY: 13,
  CUT_NUMBER: 14
}

export const FIELDS_REPORT_KEYS = {
  BASE_64_IMAGE: 'base64Image',
  SCREENSHOT_PATH: 'screenshotPath',
  RECIPIENT_EMAIL_INPUT: 'recipientEmailInput',
  RECIPIENT_EMAILS: 'recipientEmails',
  REPORT_TYPE_ID: 'reportTypeId',
  ERRORS: 'errors',
  SENDER_NAME: 'senderName',
  SELECTED_FIELDS_IDS: 'selectedFieldsIds',
  NOTES: 'notes',
  AFFECTED_HA: 'affectedHa',
  RELATED_DATE: 'relatedDate',
  REPORT_ID: 'reportId'
}

export const FIELD_SEASON_FORM_KEYS = {
  FIELD_ID: 'FIELD_ID',
  FIELD_NAME: 'FIELD_NAME',
  COMMENT: 'COMMENT',
  CUSTOMER: 'CUSTOMER',
  EXTERNAL_REFERENCE_ID: 'EXTERNAL_REFERENCE_ID',
  SEASONS: 'SEASONS',
  SEASON_LABEL: 'SEASON_LABEL',
  TYPE: 'TYPE',
  SUB_TYPE: 'SUB_TYPE',
  PLANTATION_DATE: 'PLANTATION_DATE',
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  FEATURES: 'FEATURES',
  SURFACE: 'SURFACE',
  SEASON_ID: 'SEASON_ID'
}

export const BASIC_FIELD_SEASON_FORM_KEYS = [
  FIELD_SEASON_FORM_KEYS.SEASON_LABEL,
  FIELD_SEASON_FORM_KEYS.TYPE,
  FIELD_SEASON_FORM_KEYS.SUB_TYPE,
  FIELD_SEASON_FORM_KEYS.PLANTATION_DATE
]

export const SAMPLE_FORM_KEYS = {
  NOTES: 'notes',
  TYPE: 'type',
  SAMPLE_CREATION_DATE: 'sampleCreationDate',
  LOCATION: 'location',
  PICTURE: 'picture',
  BASE_LAYER: 'baseLayer',
  SNAPSHOT_DATE: 'snapshotDate',
  EXTRA_PARAMETERS: 'extraParameters'
}

export const REQUIRED_FORM_VALIDATION_RULE = { required: true }
export const NOT_REQUIRED_FORM_VALIDATION_RULE = { required: false }

export const LANGS = ['de_DE', 'en_GB', 'es_ES', 'fr_FR', 'hu_HU', 'it_IT', 'pt_PT', 'ro_RO', 'ru_RU', 'sk_SK', 'sr_SR', 'uk_UA']
export const LANGS_COMPLETE = [
  { value: 'de', label: 'Deutsch (DE)' },
  { value: 'en', label: 'English (EN)' },
  { value: 'es', label: 'Español (ES)' },
  { value: 'fr', label: 'Français (FR)' },
  { value: 'hu', label: 'Magyar (HU)' },
  { value: 'it', label: 'Italiano (IT)' },
  { value: 'pt', label: 'Português (PT)' },
  { value: 'ro', label: 'Română (RO)' },
  { value: 'ru', label: 'Русский (RU)' },
  { value: 'sk', label: 'Slovenčina (SK)' },
  { value: 'sr', label: 'Српски (SR)' },
  { value: 'uk', label: 'Українська (UA)' }
]

export const PRED_TECH_LOG_TYPES = {
  SAC_HA: 1,
  PROD_HA: 2,
  ATR_PRED: 16,
  NITRO: 17,
  POL_PRED: 23
}

export const SEARCH_BAR_LIST_MODE = {
  ALL: {
    label: 'all',
    value: 'all'
  },
  ACTIVE: {
    label: 'active',
    value: 'active'
  },
  SELECTED: {
    label: 'selected',
    value: 'selected'
  }
}

export const SAMPLES_MODE = {
  ALL_SAMPLES: 'ALL_SAMPLES',
  SEASON_SAMPLES: 'SEASON_SAMPLES'
}

export const TRANSLATED_LINKS = {
  es: 'https://hemav.com/contacto/',
  en: 'https://hemav.com/en/contact-us/',
  pt: 'https://hemav.com/pt-pt/contato/',
  fr: 'https://hemav.com/fr/contact/',
  sr: 'https://hemav.com/en/contact-us/',
  ro: 'https://hemav.com/en/contact-us/',
  hu: 'https://hemav.com/en/contact-us/',
  sk: 'https://hemav.com/en/contact-us/',
  uk: 'https://hemav.com/en/contact-us/',
  it: 'https://hemav.com/en/contact-us/',
  ru: 'https://hemav.com/en/contact-us/',
  de: 'https://hemav.com/en/contact-us/'
}

export const FIELD_LINES_Z_INDEX = 1000
export const MEASURE_TOOL_Z_INDEX = 1100
export const SAMPLES_LAYER_Z_INDEX = 1200

export const BOTTOM_LAYERS_BASE_Z_INDEX = 10
export const TOP_LAYERS_BASE_Z_INDEX = 100

export const disabledPattern =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAPUlEQVQoU2MsKCj4P2HCBEYGAgCsgBjFcJMIKUaxEp9iDLfhUozVE9gU4/QtumK8wYKsmGD4wRQTVAgLZwDSRSZav2qkLAAAAABJRU5ErkJggg=='

export const disabledPatternSolid =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAJRJREFUKFNNkFEOBDEIQsG5/5GVDehMth9NS+GppSSRJAhAgI9eUvac95mEusWnePo9ru3ytiWCmRb5OLbE03NXeJck0T0qe1+OW/kCqb8UJ3taT9W2dGya+McEXQXAzKhiXmFrmOgRd9i3ObRaxTo1RCT471Q0YmQyqXeY9COlhJy9P5T17zeCXKpss/kIvntAafQDXdBYF/YKnGQAAAAASUVORK5CYII='

export const PREDICTIVE_REPORTS_CATEGORIES = {
  FORECAST: 'forecast',
  REPORT: 'report',
  AUXILIARY: 'auxiliary'
}

export const LAYERS_BASE_NAMES = {
  CULTIVATION_DAYS: 'gm_luvi_day_cycle',
  WATER_STRESS: 'gm_lws_mean'
}

export const TRACK_EVENTS = {
  DASHBOARD_TAB_SELECTED: 'Dashboard Tab Is Selected',
  DASHBOARD_SOURCE_SELECTED: 'Dashboard Source Is Selected',
  VIEWER_GLOBAL_MAP_SELECTED: 'Viewer Global Map Is Selected',
  VIEWER_FILE_GENERATED: 'Viewer File is Generated',

  LEFT_SIDEBAR: 'Left Sidebar',
  LEFT_SIDEBAR_CREATE_NEW_FIELD: 'Viewer Create New Field button is clicked',
  LEFT_SIDEBAR_EDIT_FIELD: 'Viewer Edit Field button is clicked',
  LEFT_SIDEBAR_MANAGE_REPORT: 'Viewer Manage Report button is clicked',
  LEFT_SIDEBAR_SEND_REPORT: 'Viewer Send Report button is clicked',
  LEFT_SIDEBAR_VIEW_STATISTICS: 'Viewer View Statistics is clicked ',
  LEFT_SIDEBAR_DOWNLOAD_SELECTED_GEOMETRIES: 'Viewer Download Selected Geometries is clicked',
  LEFT_SIDEBAR_OPEN_TERRANI_MAP: 'Viewer Open Terrani Map is clicked',
  LEFT_SIDEBAR_CROP_FILTER: 'Viewer Crops filter is selected',
  LEFT_SIDEBAR_SORT: 'Viewer Sort button is clicked',
  LEFT_SIDEBAR_SELECT_ALL_FIELDS: 'Viewer Select All button is clicked',
  LEFT_SIDEBAR_LIST_STATUS: 'Viewer List Status tab is clicked',
  LEFT_SIDEBAR_LIST_FIEL_INFO: 'Viewer Field Info button is clicked',

  FIELD_PANEL: 'Field Panel',
  FIELD_PANEL_INFO: 'Viewer Field info button is clicked',
  FIELD_PANEL_SEASON: 'Viewer Field season button is clicked',
  FIELD_PANEL_CALENDAR: 'Viewer Field season button is clicked',
  FIELD_PANEL_LAYERS: 'Viewer Field layers button is clicked',
  FIELD_PANEL_DOWNLOAD: 'Viewer Field download button is clicked',
  FIELD_PANEL_SAMPLES: 'Viewer Field samples button is clicked',
  FIELD_PANEL_FILE_GENERATOR: 'Viewer Field file generator button is clicked',

  FIELD_CLOUD_COVERAGE: 'Viewer Field Cloud Shifted coverage bar',
  FIELD_PANEL_TEMPORAL_COMPARE: 'Viewer Field Temporal Compare button is dropped',
  FIELD_PANEL_SAMPLE_ITEM: 'Viewer Field Sample item is clicked',
  FIELD_PANEL_SAMPLE_LOCATION: 'Viewer Field Sample location is clicked',
  FIELD_PANEL_SAMPLE_TOGGLE: 'Viewer Field Sample toggle button is clicked',

  FIELD_PANEL_FILE_GENERATOR_PANEL: 'Field File Generator panel',
  FIELD_PANEL_FILE_GENERATOR_DOWNLOAD: 'Field File Generator download button is clicked'
}

export const FILE_TYPES = {
  SHP: 'shp',
  PDF: 'pdf',
  RAW: 'raw'
}
