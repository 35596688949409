import React, { useState, useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import FileGeneratorTabs from './FileGeneratorTabs'
import FileGeneratorForm from '../DeliverablesComponents/forms/FileGeneratorForm'
import { FILE_TYPES } from '../../constants'

export default function FileGenerator(props) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [formatType, setFormatType] = useState(props.isSatOrRadarSelected ? FILE_TYPES.SHP : FILE_TYPES.RAW)

  const tabs = useMemo(
    () => [
      { icon: 'icon-shp', formatType: FILE_TYPES.SHP },
      { icon: 'icon-pdf', formatType: FILE_TYPES.PDF },
      { icon: 'icon-raw', formatType: FILE_TYPES.RAW }
    ],
    []
  )

  const droneFlightTabs = useMemo(() => [{ icon: 'icon-raw', formatType: FILE_TYPES.RAW }], [])

  let selectedTabs

  switch (true) {
    case !props.isSatOrRadarSelected:
      selectedTabs = droneFlightTabs
      break
    default:
      selectedTabs = tabs
  }

  const handleTabChange = newIndex => {
    setActiveTabIndex(newIndex)
    setFormatType(selectedTabs[newIndex].formatType)
  }

  return (
    <>
      <FileGeneratorTabs tabs={selectedTabs} onTabChange={handleTabChange} />
      <SwipeableViews index={activeTabIndex} onChangeIndex={handleTabChange}>
        {selectedTabs.map(tab => (
          <TabContentWrapper key={tab.formatType}>
            <FileGeneratorForm {...props} formatType={formatType} />
          </TabContentWrapper>
        ))}
      </SwipeableViews>
    </>
  )
}

const TabContentWrapper = styled.div`
  padding: 1rem;
`
