// prettier-ignore
export default {
  "-0.10 Very Low": "-0,10 Sehr niedrig",
  "% Mapa alertas": "% Warnkarte",
  "% Media Fosforo": "% Phosphor-Durchschnitt",
  "% Media Nitrogeno": "% Stickstoff-Durchschnitt",
  "% Media Potasio": "% Kalium-Durchschnitt",
  "% resiembra": "% Nachsaat",
  "0.10 Low": "0,10 Niedrig",
  "0.20 Very Low": "0,20 Sehr niedrig",
  "0.25 Very Low": "0,25 Sehr niedrig",
  "0.30 Low": "0,30 Niedrig",
  "0.30 Medium": "0,30 Mittel",
  "0.40  Low": "0,40 Niedrig",
  "0.40 Medium": "0,40 Mittel",
  "0.45 High": "0,45 Hoch",
  "0.50 High": "0,50 Hoch",
  "0.55 Medium": "0,55 Mittel",
  "0.65 Very High": "0,65 Sehr hoch",
  "0.70 High": "0,70 Hoch",
  "0.85 Very High": "0,85 Sehr hoch",
  "abonado de cobertera": "Kopfdüngung",
  "abonado de fondo": "Grunddüngung",
  "abonado de fondo ": "Grunddüngung",
  "abonado dosificado": "Dosierte Düngung",
  "abonado tasa fija": "Festrate-Düngung",
  "abonado zonificado": "Zonierte Düngung",
  "accumulated vegetative growth": "Akkumuliertes vegetatives Wachstum",
  "Acre": "Morgen",
  "active": "aktiv",
  "Add at least one recipient (+)": "Fügen Sie mindestens einen Empfänger hinzu (+)",
  "Add Field Button": "Feld hinzufügen Schaltfläche",
  "Add Field Tutorial": "Tutorial: Felder hinzufügen",
  "add location": "Standort hinzufügen",
  "Add new sample": "Neue Probe hinzufügen",
  "add photo": "Foto hinzufügen",
  "added to pending": "zu ausstehend hinzugefügt",
  "Advanced": "Erweitert",
  "advanced season parameters": "Erweiterte Saisonparameter",
  "afección": "Befall",
  "afección extensivo": "Extensiver Befall",
  "Affected Surface": "Betroffene Fläche",
  "After filling the form, you can draw the field": "Nach dem Ausfüllen des Formulars können Sie das Feld zeichnen",
  "agrodelivery": "Agrarlieferung",
  "all": "alle",
  "ALMENDRA": "MANDEL",
  "alto estrés hídrico": "Hoher Wasserstress",
  "alto pot prod": "Hohes Prod. Pot.",
  "ambiente": "Umgebung",
  "An error occured": "Ein Fehler ist aufgetreten",
  "An error occurred from the server. Please try again": "Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es erneut",
  "And that's it!": "Und das war's!",
  "anexo información": "Informationsanhang",
  "Applied filters": "Angewendete Filter",
  "Apply": "Anwenden",
  "apply date filter": "Datumsfilter anwenden",
  "Apply selection": "Auswahl anwenden",
  "Are you sure to close the editor?": "Sind Sie sicher, dass Sie den Editor schließen möchten?",
  "Are You sure?": "Sind Sie sicher?",
  "area": "Fläche",
  "Area": "Fläche",
  "ATR": "Gesamter rückgewinnbarer Zucker",
  "author": "Autor",
  "Automatic harvest": "Automatische Ernte",
  "auxiliary files": "Hilfsdateien",
  "back": "zurück",
  "bajo estrés hídrico": "Niedriger Wasserstress",
  "bajo pot prod": "Niedriges Prod. Pot.",
  "Base temperature": "Basistemperatur",
  "baseLayer": "Grundebene",
  "Basic": "Grundlegend",
  "basic season parameters": "Grundlegende Saisonparameter",
  "bipolaris_sacchari_g1": "Bipolaris sacchari \"augenfleck\" - G1",
  "bipolaris_sacchari_g2": "Bipolaris sacchari \"augenfleck\" - G2",
  "bipolaris_sacchari_g3": "Bipolaris sacchari \"augenfleck\" - G3",
  "bipolaris_sacchari_g4": "Bipolaris sacchari \"augenfleck\" - G4",
  "bipolaris_sacchari_g5": "Bipolaris sacchari \"augenfleck\" - G5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "Sowohl der erste als auch der letzte Punkt der Schnittlinie müssen außerhalb des Polygons/Kreises liegen",
  "Buried": "Vergraben",
  "calendar": "Kalender",
  "calibre": "Kaliber",
  "camera error message": "Es scheint ein Berechtigungsproblem mit der Kamera zu geben",
  "camera_unavailable": "Kamera nicht verfügbar",
  "Can't modify field with active planet subscription": "Feld mit aktiver Planet-Abonnement kann nicht geändert werden",
  "cancel": "Abbrechen",
  "cane_num": "Rohrzahl",
  "cantidad": "Menge",
  "capa base": "Grundschicht",
  "Capture screenshot": "Screenshot aufnehmen",
  "Change opacity": "Deckkraft ändern",
  "Choose your layer": "Wählen Sie Ihre Ebene",
  "Choose your tool": "Wählen Sie Ihr Werkzeug",
  "Circle Drawing": "Kreiszeichnung",
  "class": "Klasse",
  "Class values must be numerical": "Klassenwerte müssen numerisch sein",
  "classes": "Klassen",
  "classes number": "Klassenanzahl",
  "clear": "Löschen",
  "Clear all": "Alles löschen",
  "clear form": "Formular löschen",
  "clear selected fields": "Ausgewählte Felder löschen",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Durch Klicken auf diese Schaltfläche können Sie die Sichtbarkeit der ausgewählten Ebene umschalten",
  "clientDeviation": "Kundenabweichung",
  "Close": "Schließen",
  "Close editor": "Editor schließen",
  "Close selection": "Auswahl schließen",
  "Close the temporal Comparison": "Zeitlichen Vergleich schließen",
  "close_selected": "Ausgewählte schließen",
  "closed": "geschlossen",
  "cloud": "Wolke",
  "Cloud Coverage": "Wolkenbedeckung",
  "comment": "Kommentar",
  "comp. temporal target": "Zeitl. Zielvergleich",
  "comp. temporal variabilidad": "Zeitl. Variabilitätsvergleich",
  "comparación de conteo": "Zählvergleich (%)",
  "comparación de estado hídrico": "Wasserzustandsvergleich (%)",
  "comparación de estado hídrico drone": "Wasserzustandsvergleich - Drohne (%)",
  "comparación de estado hídrico sat": "Wasserzustandsvergleich - SAT (%)",
  "comparación de estimación de producción": "Produktionsschätzungsvergleich (%)",
  "comparación de fósforo": "Phosphorvergleich (%)",
  "comparación de mapa de alertas": "Warnkartenvergleich (%)",
  "comparación de mapa de faltas": "Fehlstellenkartenvergleich (%)",
  "comparación de media de conteo": "Durchschnittlicher Zählvergleich (%)",
  "comparación de media de estado hídrico": "Durchschnittlicher Wasserzustandsvergleich (%)",
  "comparación de media de fósforo": "Durchschnittlicher Phosphorvergleich (%)",
  "comparación de media de mapa de alertas": "Durchschnittlicher Warnkartenvergleich (%)",
  "comparación de media de mapa de faltas": "Durchschnittlicher Fehlstellenkartenvergleich (%)",
  "comparación de media de nitrógeno": "Durchschnittlicher Stickstoffvergleich (%)",
  "comparación de media de potasio": "Durchschnittlicher Kaliumvergleich (%)",
  "comparación de media de producción total": "Durchschnittlicher Gesamtproduktionsvergleich (%)",
  "comparación de media de rendimiento graso": "Durchschnittlicher Fettleistungsvergleich (%)",
  "comparación de medias de estimación de producción": "Durchschnittlicher Produktionsschätzungsvergleich (%)",
  "comparación de medias de ndvi": "Durchschnittlicher NDVI-Vergleich (%)",
  "comparación de medias de proteína": "Durchschnittlicher Proteinvergleich (%)",
  "comparación de medias de variabilidad": "Durchschnittlicher Variabilitätsvergleich (%)",
  "comparación de ndvi": "NDVI-Vergleich (%)",
  "comparación de nitrógeno": "Stickstoffvergleich (%)",
  "comparación de nitrógeno relativo": "Relativer Stickstoffvergleich (%)",
  "comparación de potasio": "Kaliumvergleich (%)",
  "comparación de producción total": "Gesamtproduktionsvergleich (%)",
  "comparación de proteína": "Proteinvergleich (%)",
  "comparación de rendimiento graso": "Fettleistungsvergleich (%)",
  "comparación de sacarosa": "Saccharosevergleich",
  "comparación de variabilidad": "Variabilitätsvergleich (%)",
  "confirm": "Bestätigen",
  "Congratulations! Your field has been created.": "Glückwunsch! Ihr Feld wurde erstellt",
  "Congratulations! Your field has been edited.": "Glückwunsch! Ihr Feld wurde bearbeitet",
  "Contact": "Kontakt",
  "Contenido de sacarosa ( % )": "Saccharosegehalt ( % )",
  "conteo - pdf rgb": "Zählung - PDF RGB",
  "conteo - shps": "Zählung - SHPs",
  "conteo de individuos": "Individuenzählung",
  "conteo lechugas": "Salatzählung",
  "continue": "Fortfahren",
  "Cookies and Privacy": "Cookies und Datenschutz",
  "CORN": "MAIS",
  "correction": "Korrektur",
  "Corte nitrogeno": "Stickstoffschnitt",
  "cost": "Kosten",
  "count (units)": "Zählung (Einheiten)",
  "Counting": "Zählung",
  "create": "Erstellen",
  "Create New Field": "Neues Feld erstellen",
  "CREATE_RANGES": "Basierend auf dem Histogramm des Bildes Bereiche mit einem Prozentsatz ähnlicher Daten in jedem Bereich erstellen.",
  "created": "Erstellt",
  "createFieldForm_create_button": "Feld erstellen",
  "createFieldForm_draft_button": "Entwurf speichern",
  "createFieldForm_info_message": "Das Speichern als Entwurf ermöglicht es, Daten vorübergehend auf diesem Gerät zu speichern, sodass Sie Geometrie bearbeiten oder hinzufügen können, bevor Sie in Layers speichern.",
  "crecimiento cultivo": "Pflanzenwachstum",
  "Crop": "Kultur",
  "Crop type": "Kulturart",
  "CROP_INSPECTION": "Kulturinspektion",
  "cultive": "Kultur",
  "Cultivo": "Kultur",
  "current season": "Aktuelle Saison",
  "current selection could take a moment to process": "Der aktuelle Datumsbereich wurde erweitert, die Anfrage könnte einen Moment dauern, bis sie verarbeitet ist",
  "Customer": "Kunde",
  "customerProductionEstimation": "Kundenproduktionsschätzung",
  "customers": "Kunden",
  "cut": "Schnitt",
  "Cutter": "Schneider",
  "dashboard": "Übersicht",
  "date": "Datum",
  "DD/MM/YYYY": "TT.MM.JJJJ",
  "delete": "Löschen",
  "Delete": "Löschen",
  "Delete downloaded report": "Heruntergeladenen Bericht löschen",
  "delete downloaded sample": "Heruntergeladene Probe löschen",
  "Delete field": "Feld löschen",
  "delete layers?": "Ebenen löschen?",
  "delete sample": "Probe löschen",
  "delete sample edition": "Probenbearbeitung löschen",
  "delete_geometries_action_button": "Löschen",
  "delete_geometries_alert_message": "Sind Sie sicher, dass Sie diese Geometrie löschen möchten?",
  "delete_geometries_alert_title": "Geometrie löschen",
  "DeleteField": "Das Feld wurde gelöscht",
  "Deliverables tutorial": "Tutorial: Lieferungen",
  "Description": "Beschreibung",
  "Deselect": "Abwählen",
  "deselect all": "Alle abwählen",
  "deviation": "Abweichung",
  "Did you forget your password ?": "Haben Sie Ihr Passwort vergessen?",
  "discard_changes_alert_message": "Sie haben ungespeicherte Änderungen. Möchten Sie diese wirklich verwerfen und diesen Bildschirm verlassen?",
  "discard_changes_alert_title": "\"Änderungen verwerfen?",
  "discard_changes_cancel_button": "Abbrechen",
  "discard_changes_discard_button": "Verwerfen",
  "disponible en visor": "Im Viewer verfügbar",
  "do you want to delete": "Möchten Sie löschen",
  "documentation": "Dokumentation",
  "don't show again": "Nicht mehr anzeigen",
  "Done": "Fertig",
  "dosificación genérica": "Generische Dosierung",
  "Dosis Media Cliente": "Durchschnittliche Kundendosis",
  "Dosis media Propuesta": "Vorgeschlagene Durchschnittsdosis",
  "dosis riego": "Bewässerungsdosis",
  "Download": "Herunterladen",
  "download failed": "Die folgenden Downloads konnten nicht abgeschlossen werden",
  "Download list": "Downloadliste",
  "Download selected fields samples": "Ausgewählte Feldproben herunterladen",
  "download selected geometries": "Ausgewählte Geometrien herunterladen",
  "download values": "Werte herunterladen",
  "downloaded": "Heruntergeladen",
  "downloading layers": "Ebenen werden heruntergeladen",
  "downloads": "Downloads",
  "Drag and drop your layers to arrange them in the list.": "Ziehen und ablegen Sie Ihre Ebenen, um sie in der Liste anzuordnen",
  "Drag Fields": "Felder ziehen",
  "Drag your layers": "Ziehen Sie Ihre Ebenen",
  "Draw Field": "Feld zeichnen",
  "dron": "Drohne",
  "Dron sectores": "Drohnensektoren",
  "drop here": "Hier ablegen",
  "Dry": "Trocken",
  "dry matter": "Trockenmasse",
  "dryMatterField": "Trockenmasse",
  "dryMatterLaboratory": "Trockenmasse Labor",
  "dsm": "DSM",
  "earliest harvest date": "Frühestes Erntedatum",
  "edit": "Bearbeiten",
  "Edit Field": "Feld bearbeiten",
  "Edit geometry": "Geometrie bearbeiten",
  "edit range": "Bereich bearbeiten",
  "Edit values": "Werte bearbeiten",
  "ej 100": "Bsp. 100",
  "End Date": "Enddatum",
  "Equal Interval": "Gleiche Intervalle",
  "Equidistant ranges": "Äquidistante Bereiche",
  "error fetching classes": "Der Himmel ist zu bewölkt am ausgewählten Datum. Passen Sie den Kalenderfilter an, um einen klareren Tag zu finden.",
  "estado hídrico": "Wasserstatus",
  "estado hídrico (ndmi)": "Hydrischer Status (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Schätzung",
  "estimación producción": "Produktionseinschätzung",
  "Estimacion produccion ( Tn / Ha )": "Produktionseinschätzung ( Tn / ha )",
  "estimación producción cualitativa": "Qualitative Produktionseinschätzung",
  "estimación proteína": "Proteinschätzung",
  "Estimacion proteina ( % )": "Proteinschätzung ( % )",
  "estimación sacarosa": "Geschätzte Saccharose",
  "estimación sacarosa ": "Geschätzte Saccharose",
  "estimación sacarosa cualitativa": "Qualitative Saccharoseschätzung",
  "Estimacion Total ( Kg )": "Gesamtschätzung ( kg )",
  "estimation gauging": "Schätzungsmessung",
  "estrategia de dosificacion": "Dosierungsstrategie",
  "estrés hídrico (lws)": "Wasserstress (LWS)",
  "evolución de NDVI": "NDVI-Entwicklung",
  "evolution": "Entwicklung",
  "Ex : comment": "Bsp: Kommentar",
  "Ex : field R02": "Bsp: Feld R02",
  "Ex : Tresbolillos, row, tree": "Bsp: Tresbolillos, Reihe, Baum",
  "expected_final_weight": "Erwartetes Endgewicht",
  "external_id": "Externe ID",
  "extra parameters": "Zusätzliche Parameter",
  "falhas carreadores": "Transportlücken",
  "falhas internas": "Interne Lücken",
  "falhas terraços": "Terrassenlücken",
  "falla": "Lücke",
  "fat performance": "Fettleistung",
  "faults map": "Fehlerkarte",
  "fdn": "Fdn",
  "Fecha de vuelo": "Flugdatum",
  "Fecha Plantacion": "Pflanzdatum",
  "Feddan": "Feddan",
  "fertilization strategy": "Düngestrategie",
  "Fertilizer recommendation": "Düngungsempfehlung",
  "field": "Feld",
  "Field Name": "Feldname",
  "Field without geometry": "Feld ohne Geometrie",
  "FIELD_FILTER": "Feldfilter",
  "field_name": "Feldname",
  "FIELD_TEMPORAL_EVOLUTION": "Zeitliche Entwicklung",
  "fields": "Felder",
  "Fields without geometries": "Felder ohne Geometrien",
  "File cannot be generated due to high amounts of clouds. Please select another date": "Aufgrund der starken Bewölkung kann die Datei nicht generiert werden. Bitte wählen Sie ein anderes Datum aus.",
  "File generator": "Dateigenerator",
  "File Generator": "Dateigenerator",
  "File name": "Dateiname",
  "file type": "Dateityp",
  "file-is-not-available-in-selected-language": "Diese Datei ist in der ausgewählten Sprache nicht verfügbar. Generiert in Englisch.",
  "Fill plantation date of current field, showing one year of historic data": "Pflanzdatum des aktuellen Feldes ausfüllen, zeigt ein Jahr historische Daten an",
  "Fill the field form": "Feldformular ausfüllen",
  "Fill the form to apply for all our services": "Füllen Sie das Formular aus, um sich für alle unsere Dienstleistungen zu bewerben",
  "filtered field": "Gefiltertes Feld",
  "filtered fields": "Gefilterte Felder",
  "Filters": "Filter",
  "final_weight": "Endgewicht",
  "fitopatógenos": "Phytopathogene",
  "Flight Slider": "Flugschieberegler",
  "FLIGHT_FILTER": "Zeitlicher Vergleich",
  "flights type": "Flugtyp",
  "fmsavi": "FMSAVI",
  "for everyone": "Für alle",
  "for me": "Für mich",
  "forecasts": "Prognosen",
  "formulario de abonado": "Düngungsformular",
  "formulario de dosis": "Dosierungsformular",
  "fósforo foliar": "Blattphosphor",
  "fr": "Francium",
  "Fraction": "Fraktion",
  "free": "Frei",
  "from": "Von",
  "fruits_100_weight": "100 Fruchtgewicht",
  "fruits_actual1_weight": "Tatsächliches Fruchtgewicht",
  "Fuente de informacion": "Quelle",
  "gallery": "Galerie",
  "gauging date": "Probenahmedatum",
  "generation error": "Generierungsfehler",
  "geometry": "Geometrie",
  "get a free trial": "Kostenlose Probeversion erhalten",
  "Get back to editor": "Zurück zum Editor",
  "global files": "Herunterladen von vorverarbeitungs, trainings und prognosedateien im PDF und CSV format",
  "global layer": "Globale Schicht",
  "gm_cut_number": "Schnittnummer",
  "gm_data_generation": "Satellitendatum (Wochen)",
  "gm_day_cycle": "Tage des Anbaus",
  "gm_harvest_estimated_date": "Geschätztes Erntedatum (Wochen)",
  "gm_hemav_atr_estimation": "TRS-Vorhersage",
  "gm_hemav_pol_estimation": "Polarisation Vorhersage",
  "gm_hemav_prodph_estimation": "Ertragsvorhersage",
  "gm_hemav_realprodph_lastseason": "Tatsächlicher Ertrag der vorherigen Saison",
  "gm_hemav_sac_estimation": "Saccharosevorhersage",
  "gm_luvi_date_generation": "Satellitendatum - LUVI (Wochen)",
  "gm_luvi_day_cycle": "Anbautage (LUVI)",
  "gm_luvi_mean": "Vegetatives Wachstum (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilität (NDVI-LUVI)",
  "gm_lws_mean": "Wasserstress (LWS)",
  "gm_ndmi_date_generation": "Satellitendatum - NDMI (Wochen)",
  "gm_ndmi_day_cycle": "Anbautage (NDMI)",
  "gm_ndre_date_generation": "Satellitendatum - NDRE (Wochen)",
  "gm_ndre_day_cycle": "Anbautage (NDRE)",
  "gm_sar_biomass_cat": "Kategorische Biomasse (SAR)",
  "gm_season_label": "Saison",
  "gm_sub_type_name": "Sorte",
  "gm_type_name": "Kulturpflanze",
  "go to field": "Zum Feld gehen",
  "grid": "Gitter",
  "harvest date": "Erntedatum",
  "harvest planning": "Ernteplanung",
  "HARVEST_PLANNING_TOOL": "Ernteplanungswerkzeug",
  "HARVEST_PREDICTION": "Ernteprognose",
  "Hectare": "Hektar",
  "Hemav Default": "Hemav Standard",
  "HEMAV Default": "Hemav Standard",
  "Here, you can play with the preview of your different layers.": "Hier können sie mit der vorschau ihrer verschiedenen ebenen spielen.",
  "hide": "Verstecken",
  "Hide All Layers": "Alle Schichten ausblenden",
  "hide filter list": "Filterliste ausblenden",
  "Hide Global Layer": "Globale Schicht ausblenden",
  "Hide Measure Tools": "Messwerkzeuge ausblenden",
  "high": "Hoch",
  "High contrast map": "Hochkontrastkarte",
  "high level": "Hoch",
  "high need": "Hoher Bedarf",
  "high water stress": "Hoher Wasserstress",
  "higher clay content": "Höherer Tongehalt",
  "humedad de suelo - beta (%)": "Bodenfeuchtigkeit - Beta (%)",
  "humedad vegetativa (ndmi)": "Vegetative Feuchtigkeit (NDMI)",
  "Hybrid map": "Hybride Karte",
  "Hybrid View": "Hybridansicht",
  "hydric status": "Hydrischer Status - NDMI",
  "hydric status drone": "Hydrischer Status - Drohne",
  "If foliar sampler haven't been made, the date you must enter is the current": "Falls keine Blattprobenahme durchgeführt wurde, geben Sie das aktuelle Datum ein.",
  "If you have two or more flights, you can compare them temporally using this tool.": "Wenn Sie zwei oder mehr Flüge haben, können Sie diese mit diesem Werkzeug zeitlich vergleichen.",
  "If you have undone an action, you can press this button to redo it.": "Wenn Sie eine Aktion rückgängig gemacht haben, können Sie diese Schaltfläche drücken, um sie wiederherzustellen.",
  "image": "Bild",
  "Impacto estimado": "Geschätzte Auswirkung",
  "In this tab, you can download the generated PDFs.": "In diesem Tab können Sie die generierten PDFs herunterladen.",
  "individuals_num": "Individuenzahlen",
  "infection": "Infektion",
  "info": "Info",
  "Information": "Informationen",
  "informe": "Bericht",
  "integración con maquinaria": "Integration mit Maschinen",
  "Invalid credentials": "Ungültige Anmeldedaten",
  "invalid date": "Ungültiges Datum",
  "Invalid email": "Ungültige E-Mail",
  "invalid_geometry_error": "Ungültige Geometrie",
  "invest": "Investieren",
  "Irrigation recommendation": "Bewässerungsempfehlung",
  "Irrigation type": "Bewässerungstyp",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area.": "Es wird üblicherweise angepasst, um die Menge an Dünger oder Samen pro Flächeneinheit festzulegen.",
  "item_num": "Artikelnummer",
  "Keep selection": "Auswahl beibehalten",
  "label": "01.Etikett",
  "Label": "Etikett",
  "languaje": "Sprache",
  "last sample": "Letzte Probe",
  "lat": "Breitengrad",
  "layers": "Ebenen",
  "layers estrés hídrico (lws)": "Ebenen Wasserstress (LWS)",
  "layers status": "Ebenenstatus",
  "Legal limitation": "Rechtliche Einschränkung",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"Ringfleck\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"Ringfleck\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"Ringfleck\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"Ringfleck\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"Ringfleck\" - g5",
  "letter only": "Nur Buchstaben",
  "linear meters": "Linearmeter",
  "líneas de cultivo": "Anbaulinien",
  "list": "Liste",
  "loading": "Lädt",
  "location": "Standort",
  "Log in": "Einloggen",
  "logout": "Ausloggen",
  "lon": "Längengrad",
  "looks like its taking too long": "Es scheint, als würde es zu lange dauern",
  "low": "Niedrig",
  "low level": "Niedriges Niveau",
  "low need": "Niedriger Bedarf",
  "low water stress": "Niedriger Wasserstress",
  "lower clay content": "Niedriger Tonanteil",
  "LUVI variability": "LUVI-Variabilität",
  "Machines Type": "Maschinentyp",
  "main_macronutrient": "Haupt-Makronährstoff",
  "malas hierbas": "Unkräuter",
  "malas hierbas - hoja estrecha": "Unkräuter - Schmalblättrig",
  "malas hierbas - hoja larga": "Unkräuter - Breitblättrig",
  "malas hierbas - zonificado": "Unkräuter - Zoniert",
  "malas hierbas shps": "Unkräuter SHPs",
  "malas hierbas shps - hoja estrecha": "Unkräuter SHPs - Schmalblättrig",
  "malas hierbas shps - hoja larga": "Unkräuter SHPs - Breitblättrig",
  "malas hierbas shps - zonificado": "Unkräuter SHPs - Zoniert",
  "maleza": "Unkraut",
  "Manage report": "Bericht verwalten",
  "mapa de faltas": "Ernteausfälle",
  "max 2 years": "Maximal 2 Jahre",
  "max comparable fields": "Maximal vergleichbare Felder",
  "maximo de variacion": "Maximale Variation",
  "máximo vigor dos meses (ndvi)": "Maximales Vigor zwei Monate (NDVI)",
  "máximo vigor un año (ndvi)": "Maximales Vigor ein Jahr (NDVI)",
  "Maximun cloud coverage": "Maximale Wolkenbedeckung",
  "mean": "Mittelwert",
  "Measures tool tutorial": "Tutorial: Messwerkzeug",
  "Measures tools": "Messwerkzeuge",
  "media de faltas": "Durchschnittliche Ernteausfälle",
  "medias": "Mittelwerte",
  "medio pot prod": "Mittlerer Pott-Produktion",
  "medium": "Mittel",
  "medium clay content": "Mittlerer Tonanteil",
  "medium level": "Mittleres Niveau",
  "medium_weight_bigSize": "Durchschnittsgewicht große Früchte",
  "medium_weight_mediumSize": "Durchschnittsgewicht mittlere Früchte",
  "medium_weight_smallSize": "Durchschnittsgewicht kleine Früchte",
  "Mensual limit": "Monatliches Limit",
  "Message": "Nachricht",
  "METEOROLOGICAL_STATISTICS": "Meteorologische Statistiken",
  "mode": "Modus",
  "model type": "Modelltyp",
  "Move the slider": "Bewege den Regler",
  "msavi": "MSAVI",
  "N_BUNCHES": "Anzahl der Trauben",
  "Name": "Name",
  "nameless field": "Feld ohne namen",
  "Natural Breaks (Jenks)": "Natürliche Brüche (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Real",
  "ndvi-curvas de nivel": "NDVI-Höhenlinien",
  "Network request failed": "Netzwerkanfrage fehlgeschlagen",
  "new sample": "Neue probe",
  "New sample created.": "Neue Probe erstellt.",
  "NITRO": "Stickstoff",
  "nitrogen chlorophyll (ndre)": "Stickstoff-Chlorophyll (NDRE)",
  "nitrogen_assimilable": "Assimilierbarer Stickstoff",
  "nitrógeno foliar": "Blattstickstoff",
  "Nivel de estres": "Stressniveau",
  "no data": "Keine Daten",
  "no data available": "Keine Daten verfügbar",
  "no data with current selection": "Keine Daten mit der aktuellen Auswahl",
  "No fields selected": "Keine Felder ausgewählt",
  "no fields to clear": "Keine Felder zum Löschen",
  "no_date": "Kein Datum",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "Es gibt keine Bilder für diesen Tag.",
  "Not a user yet ?": "Noch kein Benutzer?",
  "not enough data to display this chart": "Nicht genügend Daten, um dieses Diagramm anzuzeigen",
  "Not supported by browser": "Vom Browser nicht unterstützt",
  "notes": "Notizen",
  "number only": "Nur Zahlen",
  "Numero vuelo": "Flugnummer",
  "observations": "Beobachtungen",
  "offline access": "Offline-Zugriff",
  "offline mode": "Offline-Modus",
  "oil_yield": "Ölertrag",
  "ok": "Ok",
  "Once you are done, click this button to save the changes.": "Wenn Sie fertig sind, klicken Sie auf diese Schaltfläche, um die Änderungen zu speichern.",
  "only-classes-3-and-mode-fixed-is-valid": "Nur 'Hemav Standard' Modus und '3' Klassen sind gültige Parameter",
  "open": "öffnen",
  "Open": "Öffnen",
  "Open selection": "Auswahl öffnen",
  "open temporal comparison": "Temporären Vergleich öffnen",
  "open_selected": "Ausgewählte öffnen",
  "optimize": "Optimieren",
  "or": "oder",
  "Order by a - z": "Sortieren von a - z",
  "Order by distance to the field": "Nach Entfernung zum Feld sortieren",
  "Order by z - a": "Sortieren von z - a",
  "ourDeviation": "Abweichung von Hemav",
  "ourProductionEstimation": "Produktionsschätzung von Hemav",
  "Parcela": "Feld",
  "Password": "Passwort",
  "pb": "Pb",
  "pcd": "PCD",
  "pcd arbol": "PCD Baum",
  "pcd interpolado": "Interpoliertes PCD",
  "pcd row": "PCD Reihe",
  "pdf": "PDF",
  "pending": "ausstehend",
  "pending_field_map_info_confirm_delete_field_text": "Sind Sie sicher, dass Sie dieses Feld löschen möchten?",
  "pendingFields_missingGeo_message": "Einige Felder konnten nicht erstellt werden. Bitte fügen Sie ihnen eine Geometrie hinzu.",
  "pendingFields_serverError_message": "Es ist ein Fehler vom Server aufgetreten. Bitte versuchen Sie es erneut.",
  "perimeter": "Umfang",
  "phospohorus": "Phosphorus",
  "phosporus": "Phosphor (ppm)",
  "photo": "Foto",
  "photoid": "Foto-ID",
  "photoId": "Foto",
  "picture_path": "Bild",
  "plague": "Plage",
  "planned dosage": "Geplante Dosierung",
  "Plant emitters": "Pflanzenauslöser",
  "plantation date": "Pflanzdatum",
  "Plantation date": "Pflanzdatum",
  "plantation_end_date_range": "Pflanzendatumsbereich",
  "plantation_start_date_range": "Pflanzendatumsbereich",
  "Plantations": "Pflanzungen",
  "plants": "Pflanzen",
  "Plants distance": "Pflanzenabstand",
  "plants_density_range": "Pflanzendichtebereich",
  "Please wait or order one to have data to visualized.": "Bitte warten oder eine anfordern, um Daten zu visualisieren.",
  "Please, choose a field variety": "Bitte wählen Sie eine Feldsorte",
  "Please, choose the field owner": "Bitte wählen Sie den Feldbesitzer",
  "plural selected": "ausgewählt",
  "POL": "Polarisation",
  "Polygon drawing": "Polygonzeichnung",
  "posible falla": "Potenzielle Lücke",
  "posible maleza": "Potenzielles Unkraut",
  "positive number only": "Nur positive Zahlen",
  "positive number only and minor 100": "Nur positive Zahlen und weniger als 100",
  "pot. horticolar 90": "pot. horticolar 90",
  "pot. otros cultivos 150": "pot. andere Kulturen 150",
  "pot. otros cultivos 90": "pot. andere Kulturen 90",
  "potasio foliar": "Blattkalium",
  "potencial productivo histórico (ndvi)": "Historisches Produktionspotenzial (NDVI)",
  "precio": "Preis",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Vordefinierte Bereiche von HEMAV, die im Allgemeinen für die Mehrheit der Felder geeignet sind.",
  "prediction": "Vorhersage",
  "preprocessing": "Vorverarbeitung",
  "Press this button to add a new field.": "Drücken Sie diese Schaltfläche, um ein neues Feld hinzuzufügen.",
  "Pressing this button lets you go back to the field view.": "Durch Drücken dieser Schaltfläche können Sie zur Feldansicht zurückkehren.",
  "Preview": "Vorschau",
  "price Kg": "Preis pro kg",
  "primer vuelo": "Erster Flug",
  "PROD": "Ertrag pro Flächeneinheit",
  "producción esperada": "Erwarteter Ertrag",
  "product Kg": "Produkt Kg",
  "product Kg is mandatory": "Produkt Kg ist obligatorisch",
  "Production estimate": "Produktionseinschätzung",
  "production estimate (units)": "Produktionseinschätzung (Kg/Ha)",
  "profile": "Profil",
  "protein": "Protein",
  "puccinia_sp_g1": "puccinia sp \"Rost\" - g1",
  "puccinia_sp_g2": "puccinia sp \"Rost\" - g2",
  "puccinia_sp_g3": "puccinia sp \"Rost\" - g3",
  "puccinia_sp_g4": "puccinia sp \"Rost\" - g4",
  "puccinia_sp_g5": "puccinia sp \"Rost\" - g5",
  "Quick start with Azure": "Schnellstart mit Azure",
  "Random fertilization": "Zufällige Düngung",
  "Random seedling": "Zufälliger Sämling",
  "Range": "Bereich",
  "Ranges adjusted to the index": "Bereiche an den Index angepasst",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Bereiche derselben Größe werden basierend auf den Mindest- und Höchstwerten des Bildes generiert. In Fällen, in denen das Bild sehr homogen ist, ist es wahrscheinlich, dass keine Differenzierung sichtbar wird.",
  "realProduction": "Tatsächliche Produktion",
  "Recipients": "Empfänger",
  "recomendación abonado dosificado": "Verschreibung der Düngerdosierung",
  "recomendación abonado zonificado": "Zonierte Düngungsempfehlung",
  "Recomendacion de riego": "Bewässerungshinweise",
  "recomendación riego": "Bewässerungsempfehlung",
  "redo": "wiederholen",
  "Redo Action": "Aktion wiederholen",
  "Related date": "Verwandtes Datum",
  "relative nitrogen": "Relativer Stickstoff",
  "Rendimiento graso ( % )": "Fettleistung ( % )",
  "report": "Bericht",
  "Report sent correctly": "Bericht erfolgreich gesendet",
  "report type": "Berichtstyp",
  "Report type": "Berichtstyp",
  "report_type_action_description": "Vorschlag von Behandlungen oder Maßnahmen, die angewendet werden sollen.",
  "report_type_annotation_description": "Diagnose: Analyse im Feld",
  "report_type_inspection_description": "Analyse aus SCHICHTEN (wird geschlossen, sobald sie analysiert ist)",
  "report_type_result_description": "Analyse der Ergebnisse der Lösung oder Behandlung.",
  "reporte agronómico": "Agronomischer Bericht",
  "Reported by": "Berichtet von",
  "Reported fields": "Berichtete Felder",
  "reports manager": "Berichte",
  "required": "erforderlich",
  "Required": "Erforderlich",
  "Requires 3 characters minimun": "Erfordert mindestens 3 Zeichen",
  "Reset": "Zurücksetzen",
  "resiembra": "Nachsaat",
  "resolution": "SHP-Auflösung (m)",
  "resolution (only SHP)": "Auflösung (nur für SHP)",
  "retención de agua": "Wasserretention",
  "retry": "erneut versuchen",
  "rgb": "RGB",
  "richness": "Reichtum",
  "riego zonificado": "Zonierte Bewässerung",
  "riesgo malherbología": "Unkrautrisiko",
  "Ruler": "Lineal",
  "SAC": "Saccharose pro Flächeneinheit",
  "saccharose estimate": "Saccharose Schätzung",
  "sample": "Probe",
  "sample type": "Typ",
  "Sample was deleted.": "Probe wurde gelöscht.",
  "Sample was successfully modified.": "Probe wurde erfolgreich geändert.",
  "sample_creation_date": "Erstellungsdatum",
  "samples": "Proben",
  "satélite": "Satellit",
  "Satellite button tutorial": "Tutorial: Satellitenansicht",
  "Satellite map": "Satellitenkarte",
  "Satellite map 2": "Satellitenkarte 2",
  "Satellite View": "Satellitenansicht",
  "save": "Speichern",
  "Save All": "Alles speichern",
  "Save field": "Feld speichern",
  "save_": "speichern",
  "search": "Suche",
  "Search": "Suche",
  "season": "Saison",
  "Season label": "Saisonetikett",
  "season name": "Saisonname",
  "SEASON_EVOLUTION": "Saisonentwicklung",
  "Sector": "Sektor",
  "sectores target": "Zielsektoren",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Siehst du? Jetzt lässt dich ein erneuter Klick auf die Schaltfläche zur Satellitenansicht zurückkehren.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Zweiter Flug",
  "Select": "Auswählen",
  "select a crop and sample": "Wählen Sie eine Kulturpflanze und eine Probe aus",
  "select all": "Alle auswählen",
  "Select all": "Alle auswählen",
  "select at least one field": "Wählen Sie mindestens ein Feld aus",
  "select fields by": "Felder auswählen nach",
  "select fields by variability range": "Felder nach Variabilitätsbereich auswählen",
  "select fields to open temporal comparison": "Wählen Sie Felder aus, um einen Zeitvergleich zu öffnen",
  "select one": "Wählen Sie eins aus",
  "select one customer please": "Bitte wählen Sie einen Kunden aus",
  "select one subtype please": "Bitte wählen Sie einen Untertyp aus",
  "select one type please": "Bitte wählen Sie einen Kulturtyp aus",
  "select sample base layer and date": "Basisebene und Datum der Probe auswählen",
  "select sample location": "Probenstandort auswählen",
  "select season": "Saison auswählen",
  "selected": "ausgewählt",
  "Selected fields names": "Ausgewählte Feldnamen",
  "Selected fields samples": "Proben ausgewählter Felder",
  "Selection includes too much information, try selecting a shorter date range": "Die Auswahl enthält zu viele Informationen, versuchen Sie, einen kürzeren Datumsbereich auszuwählen.",
  "Send": "Senden",
  "Send report": "Bericht senden",
  "settings": "Einstellungen",
  "Share fields report": "Feldbericht teilen",
  "show all": "alle anzeigen",
  "Show All Layers": "Alle Ebenen anzeigen",
  "Show current season only": "Nur die aktuelle Saison anzeigen",
  "show fields with no data": "Felder ohne Daten anzeigen",
  "show filter list": "Filterliste anzeigen",
  "Show Global Layer": "Globale Ebene anzeigen",
  "Show Measure Tools": "Messwerkzeuge anzeigen",
  "shp-tooltip": "Diese Zahlen können gemäß den Anforderungen geändert werden und sind jeder Geometrieklasse in der exportierten SHP-Attributtabelle zugeordnet. Häufig angepasst, um die Menge an Dünger oder Samen pro Flächeneinheit festzulegen.",
  "singular selected": "ausgewählt",
  "Slice tool only works on Polygons and Circle": "Das Schneidwerkzeug funktioniert nur bei Polygonen und Kreisen.",
  "Slider Tool": "Reglerwerkzeug",
  "soil": "Boden",
  "soil_type": "Bodenart",
  "sólo para la generación de SHP": "Nur für die SHP-Generierung",
  "Sort": "Sortieren",
  "sort by date": "Nach Datum sortieren",
  "Start date": "Startdatum",
  "static layers": "statische Ebenen",
  "Statistics": "Statistiken",
  "status": "Status",
  "stem_num": "Stammnummer",
  "Streets distance": "Abstand zu Straßen",
  "Subtype": "Untertyp",
  "Suc/Ha": "Suc/Ha",
  "sum_of_bases": "Summe der Basen",
  "Superficie ( Ha )": "Fläche ( Ha )",
  "Superficie Sector ( Ha )": "Sektorfläche ( Ha )",
  "surfaceUnit": "Flächeneinheit",
  "switch between all samples and current season samples": "Wechseln zwischen allen Proben und Proben der aktuellen Saison",
  "Switch to hybrid map": "Zu hybrider Karte wechseln",
  "Switch to Mapbox": "Zu Mapbox wechseln",
  "Switch to satellite map": "Zu Satellitenkarte wechseln",
  "Switch to Terrain map": "Zu Geländekarte wechseln",
  "target date": "Zieldatum",
  "target multiparcela": "Multifeld-Ziel",
  "tell us": "Sag uns",
  "Temporal Comparison tutorial": "Tutorial: Temporaler Vergleich",
  "Terrain map": "Geländekarte",
  "textura del suelo": "Bodenstruktur",
  "The date should be in DD/MM/YYYY format": "Das Datum muss im DD/MM/YYYY-Format vorliegen.",
  "The distance must be in meters": "Die Entfernung muss in Metern angegeben werden.",
  "the extra parameters will be removed": "die zusätzlichen Parameter werden entfernt",
  "the following layers are no longer available in offline mode, download again if necessary": "die folgenden Ebenen sind im Offline-Modus nicht mehr verfügbar, laden Sie sie bei Bedarf erneut herunter",
  "The production must be numeric/decimal": "Die Produktion muss numerisch/dezimal sein.",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "Die rechte Spalte zeigt den Gesamtbetrag, der gemäß dem eingegebenen Wert pro Klasse und dem Gesamtbetrag pro Parzelle unten angewendet werden soll.",
  "The sample location must be inside one of the fields.": "Der Standort der Probe muss innerhalb eines der Felder liegen.",
  "There are no samples to download": "Es gibt keine Proben zum Herunterladen.",
  "There is no operated flight on this field.": "Es gibt keinen betriebenen Flug auf diesem Feld.",
  "Thermal Accumulation": "Thermische Akkumulation",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Diese Zahlen können gemäß den Anwendungsbedürfnissen geändert werden und sind jeder Geometrie in der Attributtabelle des exportierten SHP zugeordnet.",
  "This action will delete the field and all its related data": "Diese Aktion löscht das Feld und alle zugehörigen Daten.",
  "this action will modify your field selection": "Diese Aktion ändert Ihre Feldauswahl.",
  "this action will open field view and lose current field selection": "Diese Aktion öffnet die Feldansicht und verliert die aktuelle Feldauswahl.",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Mit diesem Button können Sie Linien oder Polygone zeichnen, um eine Entfernung oder Fläche zu messen.",
  "This button allows you to switch to the hybrid view.": "Dieser Knopf ermöglicht es Ihnen, zur hybriden Ansicht zu wechseln.",
  "This can take a long time": "Das kann lange dauern.",
  "This field is not downloaded.": "Dieses Feld ist nicht heruntergeladen.",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Dieses Werkzeug ermöglicht es Ihnen, Polygone zu schneiden. Verwenden Sie Einzelklicks, um die Linie zu zeichnen, und einen Doppelklick, um das Zeichnen abzuschließen.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Dieses Werkzeug ermöglicht es Ihnen, Polygone zu zeichnen. Versuchen Sie, ein einfaches zu zeichnen. Stellen Sie sicher, dass Sie es schließen!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Dieses Werkzeug ermöglicht es Ihnen, die Entfernung zwischen einer Reihe von Punkten zu messen. Verwenden Sie Einzelklicks, um die Punkte festzulegen, und einen Doppelklick, um das Zeichnen abzuschließen.",
  "Those fields are mandatory": "Diese Felder sind obligatorisch.",
  "TIME_COMPARISON_DROP_ZONE": "Hier ablegen...",
  "TINTA BARROCA": "BAROCKTINTA",
  "TINTA FRANCISCA": "FRANCISCANER TINTA",
  "TINTO": "ROT",
  "TINTO CÃO": "ROT HUND",
  "tipo de dosificacion": "Dosierungsart",
  "to": "Bis",
  "today": "heute",
  "todos": "Alle",
  "tons of cane - annual vision": "Tonnen Zuckerrohr - Jahresansicht",
  "Total": "Gesamt",
  "total production": "Gesamtproduktion (Kg)",
  "total_fruits_bigSize": "Gesamt große Früchte",
  "total_fruits_mediumSize": "Gesamt mittlere Früchte",
  "total_fruits_smallSize": "Gesamt kleine Früchte",
  "total_weight": "Gesamtgewicht",
  "total_weight_bigSize": "Gesamtgewicht große Früchte",
  "total_weight_canes": "Gesamtgewicht der Rohre",
  "total_weight_mediumSize": "Gesamtgewicht mittlere Früchte",
  "total_weight_smallSize": "Gesamtgewicht kleine Früchte",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Schulung",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "Versuchen Sie unsere mobile App für ein besseres Erlebnis",
  "type": "Kultur",
  "Type": "Kulturtyp",
  "type here your notes": "Geben Sie hier Ihre Notizen ein",
  "Type your text": "Geben Sie Ihren Text ein",
  "type_sampler": "Typ-Sampler",
  "undo": "rückgängig machen",
  "Undo Action": "Aktion rückgängig machen",
  "unidades": "Einheiten",
  "unitSystem": "Einheitensystem",
  "Unknown": "Unbekannt",
  "unnamed field": "Unbenanntes Feld",
  "unsaved changes will be lost": "Nicht gespeicherte Änderungen gehen verloren",
  "update sample": "Probe aktualisieren",
  "update_draft": "Entwurf aktualisieren",
  "updated": "Aktualisiert",
  "Use this buttons to select which layer to compare.": "Verwenden Sie diese Schaltflächen, um auszuwählen, welche Ebene verglichen werden soll.",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Verwenden Sie dieses Werkzeug, um zwei Flüge zeitlich zu vergleichen. Ziehen Sie dazu einen der Flüge in den Flug, mit dem Sie vergleichen möchten.",
  "Use this tool to freely move the created polygons.": "Verwenden Sie dieses Werkzeug, um die erstellten Polygone frei zu bewegen.",
  "use_layers_web_to_create_large_fields": "Verwenden Sie Layers Web, um Felder größer als 10 000 ha zu erstellen",
  "used": "verwendet",
  "Username": "Benutzername",
  "users": "Benutzer",
  "Value": "Wert",
  "variabilidad multiparcela": "Multifeldvariabilität",
  "variabilidad parcela (vra)": "Variable Rate Area (VRA)",
  "variability": "Variabilität",
  "Variedad": "Unterkultur",
  "vegetative growth": "Vegetatives Wachstum",
  "vegetative growth per day": "Tägliches vegetatives Wachstum",
  "vegetative growth variability": "Variabilität des vegetativen Wachstums",
  "Ver detalles": "Details anzeigen",
  "very high": "Sehr hoch",
  "very high level": "Sehr hohes Niveau",
  "very low": "Sehr niedrig",
  "very low level": "Sehr niedriges Niveau",
  "view": "Ansicht",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "Vigor / Pflanze",
  "vigor acumulado dos meses (ndvi)": "Kumuliertes Vigor zwei Monate (NDVI)",
  "vigor acumulado un año (ndvi)": "Kumuliertes Vigor ein Jahr (NDVI)",
  "vigor vegetativo": "Vegetatives Vigor",
  "vigor vegetativo - pcd": "PCD - Vegetatives Vigor",
  "vigor vegetativo (ndvi)": "Vegetatives Vigor (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vegetatives Vigor (NDVI) - Frühe Phasen",
  "vigor zonificado": "Vigor-Zonierung",
  "VINHÃO": "VINHÃO",
  "Visibility": "Sichtbarkeit",
  "Vulnerable area": "Verwundbares Gebiet",
  "wait": "Warten",
  "warning": "Warnung",
  "warning map": "Warnkarte",
  "Water level": "Wasserstand",
  "water stress": "Wasserstress",
  "We are processing the data for your flight. Results will be available soon.": "Wir verarbeiten die Daten für Ihren Flug. Ergebnisse sind bald verfügbar.",
  "weeds area": "Unkrautfläche",
  "week": "Woche",
  "weight_1": "Gewicht 1",
  "weight_2": "Gewicht 2",
  "weight_3": "Gewicht 3",
  "weight_4": "Gewicht 4",
  "weight_5": "Gewicht 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Mit diesem Werkzeug können Sie Kreise zeichnen. Klicken Sie, um den Mittelpunkt zu platzieren, und klicken Sie erneut, um den Radius auszuwählen.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area.": "Mit diesem Werkzeug können Sie ein Polygon mit 3 oder mehr Punkten zeichnen, um seine Fläche zu messen.",
  "without warning": "Ohne Warnung",
  "Wrong credentials": "Ungültige Anmeldedaten",
  "year": "Jahr",
  "years": "Jahre",
  "You can change here the information of the new field.": "Sie können hier die Informationen des neuen Feldes ändern.",
  "You can move this bar to compare the layers temorally.": "Sie können diese Leiste verschieben, um die Ebenen zeitlich zu vergleichen.",
  "You can only compare dates from the same sources": "Sie können nur Daten von denselben Quellen vergleichen.",
  "You can use this button at any moment to undo your last actions.": "Sie können diesen Knopf jederzeit verwenden, um Ihre letzten Aktionen rückgängig zu machen.",
  "You can use this slider to change the opacity of the selected layer.": "Sie können diesen Regler verwenden, um die Opazität der ausgewählten Ebene zu ändern.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "Sie können kein Feld erstellen, da Sie keine Kunden haben. Bitte bitten Sie uns, einige hinzuzufügen.",
  "You must enter a decimal": "Sie müssen eine Dezimalzahl eingeben.",
  "You must enter an integer": "Sie müssen eine Ganzzahl eingeben.",
  "You must select an irrigation type": "Sie müssen einen Bewässerungstyp auswählen.",
  "You must type a field name": "Sie müssen einen Feldnamen eingeben.",
  "You must type a text": "Sie müssen einen Text eingeben.",
  "Your field has been successfully deleted.": "Ihr Feld wurde erfolgreich gelöscht.",
  "Your LAYERS version has expired. Click to update.": "Ihre LAYERS-Version ist abgelaufen. Klicken Sie hier, um zu aktualisieren."
}
