import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectBaseMapByType } from '../../actions/viewmode'

export const connectSatellite = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      isSearchOpen: state.search.open,
      icon: state.baseMaps[state.baseMaps.findIndex(base => base.selected) % state.baseMaps.length].icon,
      label: state.baseMaps[state.baseMaps.findIndex(base => base.selected) % state.baseMaps.length].label
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      ...bindActionCreators(
        {
          selectBaseMapByType
        },
        dispatch
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
