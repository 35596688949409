import getFp from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import filter from 'lodash/filter'
import pick from 'lodash/pick'
import { getUserConfigGlobalMap, getUserRoles } from '@layers-frontend/commons/store/selectors/user'
import { QUERIED_GLOBAL_LAYERS_PERMISSIONS } from '@layers-frontend/commons/constants'

const getGlobalLayer = getFp('globalLayer')

const getGlobalMapCentroids = pipe(getGlobalLayer, getFp('centroids'))

const getGlobalMapLayers = pipe(getGlobalLayer, getFp('globalLayers'))

export const getIsGlobalLayerLegendOpen = pipe(getGlobalLayer, getFp('isGlobalLayerLegendOpen'))
export const getGlobalMapCentroidsFeatures = pipe(getGlobalMapCentroids, getFp('features'))
export const getGlobalMapFeatures = pipe(getGlobalMapLayers, getFp('features'))

export const getLegends = pipe(getGlobalLayer, getFp('legends'))

export const getSelectedLayer = pipe(getGlobalLayer, getFp('selectedLayer'))

export const getHighlightedCategory = pipe(getGlobalLayer, getFp('highlightedCategory'))

export const getSelectFilteredFields = pipe(getGlobalLayer, getFp('selectFilteredFields'))

export const getFilteredFieldIds = pipe(getGlobalLayer, getFp('filteredFieldIds'))

export const getLayerLegendIdByBaseName = baseName => state => {
  const legends = getLegends(state)
  const layer = find(legends, legend => legend.baseName === baseName)
  const layerId = get(layer, 'id')
  return layerId
}

export const getSelectedLayerLegend = state => {
  const legends = getLegends(state)
  const selectedLayer = getSelectedLayer(state)
  const selectedLayerLegend = get(legends, selectedLayer)
  return selectedLayerLegend
}

export const hasLegendsLoaded = baseName => state => {
  const legends = getLegends(state)
  const selectedLayer = get(legends, baseName)
  const legend = get(selectedLayer, 'legend')
  return !!legend
}

export const getLegendListFilteredByRoles = state => {
  const userRoles = getUserRoles(state)
  const legends = getLegends(state)

  const validLegends = Object.keys(legends).filter(legend => {
    const permissionRequired = QUERIED_GLOBAL_LAYERS_PERMISSIONS.find(({ id, necessaryRoles }) => id === legend && necessaryRoles.length)

    if (permissionRequired) {
      return permissionRequired.necessaryRoles.every(role => userRoles.includes(role))
    }
    return true
  })

  return values(pick(legends, validLegends))
}

export const getDefaultLayerId = state => {
  const defaultFromConfig = getUserConfigGlobalMap(state)
  const list = getLegendListFilteredByRoles(state)
  const layer = find(list, ({ baseName }) => baseName === defaultFromConfig)
  return get(layer, 'id')
}

export const getKeepSelection = pipe(getGlobalLayer, getFp('keepSelection'))

export const getTouchedLayers = state => {
  const legends = getLegends(state)
  const touchedLayers = filter(legends, legend => legend.touched)
  return touchedLayers
}
