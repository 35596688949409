// prettier-ignore
export default {
  "% Fallas": "Échecs",
  "% Fallas - Drone": "% Échecs - Drone",
  "% Mala Hierba": "% Mauvaise Herbe",
  "% Mala Hierba  -Drone": "% Mauvaise Herbe - Drone",
  "% Mala Hierba - Drone": "% Mauvaise Herbe - Drone",
  "% Maleza": "Mauvaises herbes",
  "% Maleza (Ciclo<60 dias)": "Mauvaises herbes",
  "ACELGA": "BETTE",
  "ACELGA VERDE": "BETTE VERTE",
  "action": "action",
  "ag_accesses_the_viewer": "k. AG accède au visualiseur ?",
  "AGUACATE": "AVOCAT",
  "AJO": "AIL",
  "ALBARICOQUE": "ABRICOT",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "LUZERNE",
  "ALGODON": "COTON",
  "aluminum": "Aluminium",
  "aluminum_saturation": "Saturation en Aluminium",
  "annotation": "annotation",
  "aphid_controller": "puceron - régulateur",
  "aphid_infested": "puceron - infestation",
  "aphid_infested_leafs": "puceron - feuilles infestées",
  "aphid_mite_total_leafs": "puceron et acarien - total des feuilles",
  "APIO": "CÉLERI",
  "apparent_density": "Densité apparente",
  "ARANDANOS": "MYRTILLES",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "RIZ",
  "assesor_name": "Nom de l'évaluateur",
  "atr": "Atr",
  "AVENA": "AVOINE",
  "BARBECHO": "JACHÈRE",
  "BATAVIA": "BATAVIA",
  "beans_10_plants": "Plantes de 10 haricots",
  "billaea_healthy_pupe": "billaea - chrysalides saines",
  "billaea_larva": "billaea - larves",
  "billaea_parasitized_larva": "billaea - larves parasitées",
  "billaea_residual_pupe": "billaea - chrysalides résiduelles",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"tache oculaire\" - incidence",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"tache oculaire\" - sévérité",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"tache oculaire\" - total des feuilles infectées",
  "BLANCO": "BLANC",
  "blastobasis_healthy_chrysalis": "blastobasis - chrysalides saines",
  "blastobasis_larva_number": "blastobasis - nombre de larves",
  "blastobasis_residual_chrysalis": "blastobasis - chrysalide résiduelle",
  "boron": "Bore",
  "bouquet10roots_2_8cm": "02.Bouquet 10 racines (2-8cm)",
  "bouquet10roots_2cm": "01.Bouquet 10 racines (0-2cm)",
  "bouquet10roots_8cm": "03.Bouquet 10 racines (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BROCOLI",
  "bud_blastobasis_drilled": "bourgeons de diatraea blastobasis perforés",
  "bud_dehydrated": "bourgeons déshydratés",
  "bud_diatrea_drilled": "bourgeons diatraea perforés",
  "bud_mechanical_damage": "dommages mécaniques aux bourgeons",
  "bud_sprout": "bourgeons - pousses",
  "bud_total": "total des bourgeons",
  "bud_turgid": "bourgeons turgescent",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "COURGETTE",
  "calcium": "Calcium",
  "calcium_saturation": "Saturation en Calcium",
  "CAÑA DE AZUCAR": "CANNE À SUCRE",
  "CAQUI": "KAKI",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Nb d'adultes sur 50 plantes",
  "casida_n_of_eggs_in_50_plants": "Cásida - Nb d'œufs sur 50 plantes",
  "casida_n_of_large_larvae_in_50_plants": "Cásida - Nº Grandes larves 50 plantes",
  "casida_n_of_small_larvae_in_50_plants": "Cásida - Nº Petites larves 50 plantes",
  "CAUCHO": "CAOUTCHOUC",
  "CEBADA": "ORGE",
  "CEBADA HIBRIDA": "ORGE HYBRIDE",
  "CEBADA MALTERA": "ORGE DE BRASSERIE",
  "CEBOLLA": "OIGNON",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 taches/feuille",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% feuilles ext avec 80-100% de sévérité",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Plante saine",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Taches isolées sur certaines feuilles (10-50%SL/100 feuilles).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Taches isolées sur certaines feuilles (5-10%SL/100 feuilles)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Taches isolées sur certaines feuilles (<5%SL/100 feuilles)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Nécrose foliaire EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Feuilles EXT : nécrose presque totale",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Feuilles avec > 100 taches",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 taches/feuille",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Nécrose sur la plupart des feuilles INT.",
  "cercospora_treatment": "Cercospora -o- Traitement",
  "CEREZA": "CERISE",
  "CESPED": "GAZON",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Chrome",
  "cic": "Cic",
  "CIRUELA": "PRUNE",
  "clay": "Argile",
  "CLEMENTINA": "CLÉMENTINE",
  "close": "fermer",
  "COLZA": "COLZA",
  "Conteo": "Comptage",
  "copper": "Cuivre",
  "cotesia_cocon": "cotesia - cocons",
  "cotesia_parasitized_larva": "cotesia - larves parasitées",
  "cotyledon": "cotylédon",
  "current_ndre_status": "a. État actuel du NDRE",
  "date_of_first_informative_email_sent": "j. Date du premier courriel informatif envoyé",
  "date_of_hemav_ndre_layer_screenshot": "h. Date de la capture d'écran de la couche NDRE de Hemav",
  "defoliator_leaf_evaluated": "défoliateur - feuilles évaluées",
  "defoliator_marasmia_sp_larva_alive": "défoliateur - marasmia sp. - larves vivantes",
  "defoliator_marasmia_sp_larva_death": "défoliateur - marasmia sp. - larves mortes",
  "defoliator_marasmia_sp_larva_per_stem": "défoliateur - marasmia sp. - larves par tige",
  "defoliator_mythimna_sp_larva_alive": "défoliateur - mythimna sp. - larves vivantes",
  "defoliator_mythimna_sp_larva_death": "défoliateur - mythimna sp. - larves mortes",
  "defoliator_mythimna_sp_larva_per_stem": "défoliateur - mythimna sp. - larves par tige",
  "defoliator_spodoptera_sp_larva_alive": "défoliateur - spodoptera sp. - larves vivantes",
  "defoliator_spodoptera_sp_larva_death": "défoliateur - spodoptera sp. - larves mortes",
  "defoliator_spodoptera_sp_larva_per_stem": "défoliateur - spodoptera sp. - larves par tige",
  "defoliator_stem_total": "défoliateur - total des tiges",
  "depth": "Profondeur",
  "desconocido": "Inconnu",
  "DESCONOCIDO": "INCONNU",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - grandes larves",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - chrysalides saines",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - larves moyennes",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - chrysalide résiduelle",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - petites larves",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - total des larves",
  "disease": "Maladie",
  "Disease": "Maladie",
  "disease_level": "08.Niveau de maladie",
  "distance_between_rows": "03.Distance entre les rangs",
  "does_it_follow_the_recommendation": "f. Suit-il la recommandation ?",
  "Drip": "Goutte à goutte",
  "dry_weight": "Poids sec",
  "effective_cation_exchange_capacity": "Capacité d'Échange Cationique Effective (CECE)",
  "ESCAROLA": "SCAROLE",
  "ESCAROLA LISA": "SCAROLE LISSE",
  "ESCAROLA RIZADA": "SCAROLE FRISÉE",
  "ESPARRAGO": "ASPERGE",
  "ESPINACA": "ÉPINARD",
  "estrés hídrico": "Stress hydrique",
  "Estrés hídrico (LWS)": "Stress Hydrique (LWS)",
  "evaluator": "10.Nom de l'évaluateur",
  "Ex": "Ex.",
  "exchangeable_acidity": "Acidité Échangeable",
  "fallas": "Lacunes",
  "fire": "Feu",
  "flowering": "Floraison",
  "forecast_yield_sampling_1": "PRÉVISION DE RENDEMENT ÉCHANTILLONNAGE 1",
  "forecast_yield_sampling_2": "PRÉVISION DE RENDEMENT ÉCHANTILLONNAGE 2",
  "forecast_yield_sampling_3": "PRÉVISION DE RENDEMENT ÉCHANTILLONNAGE 3",
  "forecast_yield_sampling_4": "PRÉVISION DE RENDEMENT ÉCHANTILLONNAGE 4",
  "fresh_weight": "Poids frais",
  "FRIJOL": "HARICOT",
  "fruits_count": "Compte des fruits",
  "FRUTAL": "FRUITIER",
  "gaps": "Lacunes",
  "Generic": "Générique",
  "GEWURZTRAMINNER": "GEWURZTRAMINER",
  "GIRASOL": "TOURNESOL",
  "gm_avg_ndvi": "Vigueur végétative (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vigueur végétative (NDVI-LUVI)",
  "gm_ndmi_mean": "Humidité végétative (NDMI) ",
  "gm_ndre_mean": "Nitrogène-Chlorophylle (NDRE)",
  "gm_ndvi_gaps": "Échecs",
  "gm_ndvi_weeds": "Mauvaises herbes",
  "gm_perc_overseeding": "% Surcharges",
  "gm_perc_weeds": "% Mauvaises Herbes",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Grammes",
  "GRANADA": "GRENADE",
  "green_dots": "points verts",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "POIS",
  "HABA": "FÈVE",
  "hanger_installation": "Installation de perchoir",
  "height": "Hauteur",
  "HIBRIDO": "HYBRIDE",
  "high irrigation": "Fort stress hydrique",
  "high_n_from_irrigation_water": "d. Fort N de l'eau d'irrigation",
  "high_n_from_nitrogen_input": "e. Fort N de l'apport en azote",
  "high_n_from_previous_years": "c. Fort N des années précédentes",
  "higher level": "Niveau supérieur",
  "HIGO": "FIGUE",
  "Humedad Vegetativa (NDMI) ": "Humidité végétative (NDMI) ",
  "humidity": "Humidité",
  "humidity_100_beans": "Humidité dans 100 haricots",
  "Imperial": "Impérial",
  "inspection": "inspection",
  "internodes_blastobasis_damage_longitude": "longueur des dégâts des entre-nœuds blastobasis",
  "internodes_blastobasis_drilled": "entre-nœuds blastobasis perforés",
  "internodes_diatraea_damage_longitude": "longueur des dégâts des entre-nœuds diatrea",
  "internodes_diatraea_drilled": "entre-nœuds diatrea perforés",
  "internodes_evaluated": "total des entre-nœuds",
  "internodes_healthy": "entre-nœuds sains",
  "internodes_mechanical_damage": "dommages mécaniques internes",
  "internodes_physiological_damage": "dommage physiologique de l'entre-nœud",
  "iron": "Fer",
  "irrigation": "IRRIGATION",
  "Irrigation": "Irrigation",
  "irrigation_dosage": "Dosage d'irrigation",
  "irrigation_uniformity": "Uniformité de l'irrigation",
  "KUMQUATS": "KUMQUATS",
  "language": "Langue du système",
  "larva_number_big": "stade larvaire - grande larve",
  "larva_number_medium": "stade larvaire - larve moyenne",
  "larva_number_small": "stade larvaire - petite larve",
  "last_fertilizer_app_date": "b. Dernière date d'application d'engrais",
  "last_irrigation": "Dernière irrigation",
  "lead": "Plomb",
  "Leaf": "Feuille",
  "leaf_moisture_status": "État d'humidité de la feuille",
  "LECHUGA": "LAITUE",
  "LECHUGA ICEBERG": "LAITUE ICEBERG",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"tache annulaire\" - incidence",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"tache annulaire\" - sévérité",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"tache annulaire\" - total des feuilles infectées",
  "LIMON": "CITRON",
  "linear_meters": "02.Mètres linéaires",
  "loam": "Limon",
  "loss_of_vigor": "Perte de vigueur",
  "low irrigation": "Faible stress hydrique",
  "lower level": "Niveau inférieur",
  "lowered_unload": "03.Abaissé au déchargement",
  "MACABEU": "MACABEU",
  "MACABEU VIURA": "MACABEU VIURA",
  "magnesium": "Magnésium",
  "magnesium_saturation": "Saturation en Magnésium",
  "MAIZ": "MAÏS",
  "maleza (ciclo<60 dias)": "Mauvaises herbes",
  "MALVASIA AROMATICA": "MALVASIA AROMATIQUE",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "MANDARINE",
  "manganese": "Manganèse",
  "MANZANA": "POMME",
  "mean level": "Niveau moyen",
  "medium irrigation": "Stress hydrique moyen",
  "medium_moisture_saturation": "Saturation en Humidité Moyenne",
  "MELOCOTON": "PÊCHE",
  "MELON": "MELON",
  "mercury": "Mercure",
  "MERLOT": "MERLOT",
  "Metric": "Métrique",
  "microorganisms_larva": "larves de microorganismes",
  "mite_grade": "grade moyen d'acarien",
  "mite_infested": "acarien - infestation",
  "mite_infested_leafs": "acarien - feuilles infestées",
  "moisture_status": "07.État d'humidité",
  "molybdenum": "Molybdène",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATEL D'ALEXANDRIE",
  "MOSCATELL ROMA": "MOSCATEL DE ROME",
  "NARANJA": "ORANGE",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "NECTARINE",
  "NECTARINA": "NECTARINE",
  "nickel": "Nickel",
  "Nitrogen": "Azote",
  "Nitrógeno Foliar": "Azote foliaire",
  "nitrógeno-clorofila (ndre)": "Azote-Chlorophylle (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Azote-Chlorophylle (NDRE)",
  "No crop or too dry": "Pas de culture ou trop sec",
  "NO CULTIVO": "PAS DE CULTURE",
  "No hay datos": "Pas de données",
  "no_gaps": "Pas de lacunes",
  "no_weeds": "Pas de mauvaises herbes",
  "NOGAL": "NOYER",
  "notification_to_the_farmer_high_n_index": "g. Notification au fermier : indice élevé d'azote",
  "Observations": "Observations",
  "OLIVO": "OLIVIER",
  "opogona_larva_number": "opogona - nombre de larves",
  "organic_material": "Matériel organique",
  "organic_oxidizable_carbon": "Carbone Organique Oxydable",
  "PANSA BLANCA": "PANSA BLANCA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PARAGUAYEN",
  "PARELLADA": "PARELLADA",
  "PATATA": "POMME DE TERRE",
  "pattern": "motif",
  "PERA": "POIRE",
  "percent_disease": "Pourcentage de maladie",
  "perkinsiella_345_instar_nymph": "perkinsiella - nymphes 3-4-5ème stade",
  "perkinsiella_adult": "perkinsiella - adultes",
  "perkinsiella_controller_coccinelidos": "perkinsiella - régulateurs - coccinelles",
  "perkinsiella_controller_crysopas": "perkinsiella - régulateurs - chrysopes",
  "perkinsiella_controller_spiders": "perkinsiella - régulateurs - araignées",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - régulateurs - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - régulateurs - zelus",
  "perkinsiella_death_adult": "perkinsiella - adultes morts",
  "perkinsiella_death_nymph": "perkinsiella - nymphes mortes",
  "petiole_sampling": "i. Échantillonnage du pétiole",
  "ph": "Ph",
  "pheromone_trap_diatraea_adults_number": "piège à phéromones - adultes diatraea",
  "pheromone_trap_installation_date": "piège à phéromones - date d'installation",
  "pheromone_trap_number": "piège à phéromones - pièges",
  "pheromone_trap_other_butterfly": "piège à phéromones - autres papillons",
  "pheromone_trap_pheromone_change": "piège à phéromones - changement de phéromone",
  "phosphorus": "Phosphore",
  "PIMIENTO": "POIVRON",
  "PINO": "PIN",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTACHE",
  "Pivot": "Pivot",
  "Plague": "Peste",
  "plague_level": "09.Niveau de peste",
  "Plant": "Plante",
  "Plant Density": "Densité de plantation",
  "plantation_date": "Date de plantation",
  "plants_per_surface_unit": "plantes par unité de surface",
  "poda": "taille",
  "pol": "Pol",
  "POMELO": "PAMPLEMOUSSE",
  "POMELOS": "PAMPLEMOUSSES",
  "potassium": "Potassium",
  "potassium_saturation": "Saturation en Potassium",
  "potential_gaps": "Lacunes possibles",
  "potential_weeds": "Mauvaises herbes possibles",
  "powdery_mildew_afa_perc": "% AFA d'oïdium",
  "powdery_mildew_perc_of_affected_plants": "% de plantes affectées par l'oïdium",
  "powdery_mildew_treatment": "traitement de l'oïdium",
  "Production": "Production",
  "pseudomonas_rubrilineans_stem_number": "nombre de tiges de pseudomonas rubrilineans",
  "pseudomonas_rubrilineans_stem_number_affected": "nombre de tiges affectées par pseudomonas rubrilineans",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "nombre de tiges avec symptômes de pseudomonas rubrilineans",
  "puccinia_melanocephala_accumulated_scale": "échelle accumulée de puccinia melanocephala",
  "puccinia_melanocephala_infestation": "infestation de puccinia melanocephala",
  "puccinia_melanocephala_infested_leafs": "feuilles infestées par puccinia melanocephala",
  "puccinia_melanocephala_points": "points de puccinia melanocephala",
  "puccinia_melanocephala_total_leafs": "total de feuilles de puccinia melanocephala",
  "puccinia_sp_incidence": "incidence de puccinia sp",
  "puccinia_sp_severity": "sévérité de puccinia sp",
  "puccinia_sp_total_infected_leafs": "total de feuilles infectées par puccinia sp",
  "PUERRO": "POIREAU",
  "Quality": "Qualité",
  "QUINOA": "QUINOA",
  "RAIGRAS": "RAY-GRASS",
  "real_leaves": "Vraies feuilles",
  "reform": "Réforme",
  "REMOLACHA": "BETTERAVE",
  "REMOLACHA AZUCARERA": "BETTERAVE À SUCRE",
  "resowing_surface": "surface de ressemis",
  "result": "résultat",
  "riego": "irrigation",
  "rodents_infestation_percent": "Rongeurs - infestation",
  "rot10roots_complete": "pourriture de 10 racines complète",
  "rot10roots_nothing": "pas de pourriture sur 10 racines",
  "rot10roots_partial": "pourriture partielle de 10 racines",
  "rust_10_25_afa": "rouille 10-25 % AFA",
  "rust_100_afa": "rouille 100 % AFA",
  "rust_25_35_afa": "rouille 25-35 % AFA",
  "rust_3_10_afa": "rouille 3-10 % AFA",
  "rust_35_50_afa": "rouille 35-50 % AFA",
  "rust_50_75_afa": "rouille 50-75 % AFA",
  "rust_75_90_afa": "rouille 75-90 % AFA",
  "rust_healthy_plant": "plante saine sans rouille",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "taches isolées de rouille sur certaines feuilles 1-9 %",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "taches isolées de rouille sur certaines feuilles 10-19 %",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "taches isolées de rouille sur certaines feuilles 20-29 %",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "taches isolées de rouille sur certaines feuilles 30-50 %",
  "rust_regrowth_restart": "redémarrage de la repousse après rouille",
  "rust_spots_leaves_100_300": "feuilles avec 100-300 taches de rouille",
  "rust_spots_leaves_20_100": "feuilles avec 20-100 taches de rouille",
  "rust_spots_leaves_50": "feuilles avec >50 % SL et <20 taches de rouille",
  "rust_treatment": "traitement contre la rouille",
  "sac": "sac",
  "Safety": "Sécurité",
  "sample_color": "couleur de l'échantillon",
  "sand": "sable",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "sévérité",
  "soca_width": "largeur de soca",
  "sodium": "sodium",
  "sodium_saturation": "Saturation en Sodium",
  "Soil": "Sol",
  "soil_moisture_status": "état d'humidité du sol",
  "SOJA": "SOJA",
  "sowing_failure": "Échec de semis",
  "sprout_dead": "germe mort",
  "sprout_total": "total des germes",
  "starch": "amidon",
  "stems_evaluated": "tiges évaluées",
  "sulfur": "soufre",
  "Superficial": "Superficiel",
  "SYRAH": "SYRAH",
  "TABACO": "TABAC",
  "TE": "THÉ",
  "TEA": "THÉ",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "TOMATE",
  "tombing": "Plantes couchées",
  "total_arsenic": "Arsenic Total",
  "total_cadmium": "Cadmium Total",
  "TOURIGA NACIONAL": "TOURIGA NACIONAL",
  "TRIGO": "BLÉ",
  "TRIGO BLANDO": "BLÉ TENDRE",
  "TRIGO DURO": "BLÉ DUR",
  "TRITICALE": "TRITICALE",
  "type_disease": "type de maladie",
  "uneven_budding": "Bourgeonnement inégal",
  "ustilago_scitaminea_infection": "infection par ustilago scitaminea",
  "ustilago_scitaminea_stem_affected": "tige affectée par ustilago scitaminea",
  "ustilago_scitaminea_stem_symptoms": "symptômes sur la tige par ustilago scitaminea",
  "ustilago_scitaminea_stem_total": "total des tiges par ustilago scitaminea",
  "ustilago_scitaminea_stem_whip": "fouet de tige par ustilago scitaminea",
  "UVA": "RAISIN",
  "UVA DE MESA": "RAISIN DE TABLE",
  "Variabilidad": "Variabilité",
  "Variabilidad (NDVI-LUVI)": "Variabilité (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "irrigation très élevée",
  "very low irrigation": "irrigation très faible",
  "VIGNE": "VIGNE",
  "Vigor Vegetativo (NDVI-Drone)": "Vigueur Végétative (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vigueur Végétative (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vigueur végétative (NDVI) - Forte vigueur",
  "VIÑA": "VIGNE",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "total des adultes diatraea dans le piège géoréférencé femelle vierge",
  "virgin_female_georeferenced_trap_female_change": "changement de femelle dans le piège géoréférencé femelle vierge",
  "virgin_female_georeferenced_trap_other_butterfly": "autres papillons dans le piège géoréférencé femelle vierge",
  "virgin_female_georeferenced_trap_point": "point du piège géoréférencé femelle vierge",
  "virgin_female_trap_diatraea_adult_number": "nombre d'adultes diatraea dans le piège femelle vierge",
  "virgin_female_trap_female_change": "changement de femelle dans le piège femelle vierge",
  "virgin_female_trap_installation_date": "date d'installation du piège femelle vierge",
  "virgin_female_trap_number": "nombre de pièges femelle vierge",
  "virgin_female_trap_other_butterfly": "autres papillons dans le piège femelle vierge",
  "VIURA": "VIURA",
  "weed_unload": "déchargement des mauvaises herbes",
  "weeds": "Mauvaises herbes",
  "Weeds": "Mauvaises herbes",
  "weight_100_beans": "poids de 100 haricots",
  "weight_kg": "06.Poids de l'échantillon",
  "whey": "petit-lait",
  "without_footwear": "sans chaussures",
  "without_gloves": "sans gants",
  "without_vest": "sans gilet",
  "XARELLO": "XAREL·LO",
  "yellowness_mild": "jaunissement léger",
  "yellowness_moderate": "jaunissement modéré",
  "yellowness_strong": "jaunissement intense",
  "yellowness_without_symptoms": "Jaunissement - Sans symptômes",
  "zinc": "Zinc"
}
